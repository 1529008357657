import React, { Component } from 'react';
import DateTime from "./DateTime";

export class AuditingUserInformation extends Component {
  render() {
    return (
      <span>
        <DateTime dateTime={ this.props.auditInfo.date }/> av { this.props.auditInfo.by.alias }
      </span>
    );
  }
}

export default AuditingUserInformation;