import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'

// To be used in forms...
export function FormHeart({ favourite, onClickCallBack }) {
  const [ value, setValue ] = useState(favourite)

  let icon, color
  if (value === true || value === 'true') {
    icon = faHeart
    color = 'red'
  } else {
    icon = farHeart
    color = 'black'
  }

  return (
    <div>
      <FontAwesomeIcon icon={ icon } style={ { color: color, fontSize: '150%' } } onClick={ onClick }/>
    </div>
  )

  function onClick() {
    let newValue =  !(value === true || value === 'true');
    setValue(newValue)
    onClickCallBack(newValue);
  }
}
