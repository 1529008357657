import React, { useEffect, useState } from 'react';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import queryString from "query-string";
import Table from "react-bootstrap/Table";
import Tags from "../components/Tags";
import Nav from "react-bootstrap/Nav";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { Heart } from "../components/Heart";
import './SearchPage.css';
import { FormHeart } from "../components/FormHeart";
import { useQuery } from "../utils/hooks";
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactStars from "react-rating-stars-component";
import {useRecoilValue} from "recoil";
import {metadataAtom} from "../states/metadata";
import {loggedInAtom} from "../states/auth";
import {Form, Row} from "react-bootstrap";

// TODO: params in the url!
const SearchPage = (props) => {
  let navigate = useNavigate()
  let query = useQuery();

  const metadata = useRecoilValue(metadataAtom);
  const loggedIn = useRecoilValue(loggedInAtom);

  const [ recipes, setRecipes ] = useState([])
  const [ isLoading, setIsLoading ] = useState(true)

  // console.log("props", props)
  const [ formData, setFormData ] = useState({
      name: query.get("name") != null ? query.get("name") : "",
      category: query.get("category") != null ? query.get("category") : "",
      tag: query.get("tag") != null ? query.get("tag") : "",
      rating: query.get("rating") != null ? query.get("rating") : "",
      favourite: query.get("favourite") != null ? query.get("favourite") : "",
      hasTested: query.get("hasTested") != null ? query.get("hasTested") : "",
      wantToTest: query.get("wantToTest") != null ? query.get("wantToTest") : ""
    }
  )

  // When formData changes, perform a new search
  useEffect(() => {
    function doSearch() {
      setIsLoading(true)

      // history.replace({ pathname: `/search?name=peppar`})

      let url = queryString.stringifyUrl({ url: '/api/recipes/search', query: formData },
        { skipNull: true, skipEmptyString: true });

      console.log("Searching for:", formData)

      axios.get(url)
        .then((response) => {
          setRecipes(response.data)
          setIsLoading(false)
        })
        .catch(console.log)
    }

    setIsLoading(true)
    doSearch()
  }, [ formData, loggedIn ]);

  return (
    <div>
      <h3>Sök</h3>

      <SearchForm loggedIn={loggedIn} onChangeCallback={handleChange} handleChangeHeart={handleChangeHeart}
                  handleSubmit={handleSubmit} state={formData} />
      <hr/>

      { isLoading
        ? <Spinner animation="border" variant="primary" size="sm"/>
        : renderRecipeTable(recipes) }
    </div>
  );

  function handleChange(event) {
    let value = event.target.value;
    let valueName = event.target.name

    console.log(valueName, " -> ", value)
    // Checkbox, urk
    if (valueName === "wantToTest" || valueName === "hasTested") {
      value = (event.target.checked === true).toString()
    }
    console.log(valueName, " -> ", value)

    setFormData(prevState => {
      return {
        ...prevState,
        [valueName]: value
      }
    })
  }

  function handleChangeHeart(value) {
    // console.log("search page: heart Click")
    setFormData(prevState => {
      return {
        ...prevState,
        favourite: value
      }
    })
  }

  function handleSubmit(event) {
    console.log("handleSubmit", formData)

    // TODO: we also get the default value false for checkboxes
    let url = queryString.stringifyUrl({ url: '/search', query: formData },
      { skipNull: true, skipEmptyString: true });

    console.log("url", url)
    // TODO: this reloads the application
    navigate(url)
    // doSearch();
    event.preventDefault();
  }

  function renderRecipeTable(recipes) {
    return (
      <>
        { isLoading }
        <Table striped bordered hover size="sm">
          <thead>
          <tr>
            <th>Namn</th>
            <th>Kategori</th>
            <th>Taggar</th>
            { loggedIn &&
            <>
              <th>Favorit</th>
              <th>Betyg</th>
              <th>Övrigt</th>
            </>
            }
          </tr>
          </thead>
          <tbody>
          { renderRecipeList(recipes) }
          </tbody>
        </Table>
      </>
    )
  }

  function renderRecipeList(recipes) {
    return <>
      { recipes.map((recipe) => {
        return (
          <tr key={ recipe.id }>
            <td><Nav as={ Link } to={ '/recipe/' + recipe.uriPath }>{ recipe.name }</Nav></td>
            <td>{ recipe.category }</td>
            <td><Tags tags={ recipe.tags }/></td>
            { loggedIn &&
            <>
              <td><Heart favourite={ recipe.userData.favourite }/></td>
              <td>
                { recipe.userData.rating > 0 &&
                <ReactStars
                  count={ 5 }
                  value={ recipe.userData.rating }
                  activeColor="#ffd700"
                  size={ 16 }
                  edit={ false }
                />
                }
              </td>
              <td>{ renderOtherColumn(recipe) }</td>
            </>
            }
          </tr>
        )
      }) }
    </>;
  }

  function renderOtherColumn(recipe) {
    if (recipe.userData.wantToTest && recipe.userData.hasTested) {
      return <>
        <FontAwesomeIcon icon={ faCheck }/><FontAwesomeIcon icon={ faExclamation }/> Vill laga fler gånger
      </>
    } else if (recipe.userData.wantToTest) {
      return <>
        <FontAwesomeIcon icon={ faExclamation }/> Vill laga
      </>
    } else if (recipe.userData.hasTested) {
      return <>
        <FontAwesomeIcon icon={ faCheck }/> Har lagat
      </>
    } else {
      return ""
    }
  }
}

const SearchForm = ({loggedIn, onChangeCallback, handleChangeHeart, onSubmitCallback, state}) => {
  // console.log("state", state)
  return (
    <div>
      <Form id={ "searchForm" } onSubmit={ onSubmitCallback }>
        <Row>
          <Col sm={ 2 } className={ "searchLabel" }>
            Namn
          </Col>
          <Col sm={ 4 }>
            <Form.Control type="text" name={ "name" } value={ state.name } onChange={ onChangeCallback }/>
          </Col>

          { loggedIn &&
          <>
            <Col sm={ 2 } className={ "searchLabel" }>
              Favorit
            </Col>
            <Col sm={ 4 } className={ "searchLabel" }>
              <FormHeart favourite={ state.favourite } onClickCallBack={ handleChangeHeart }/>
            </Col>
          </>
          }
        </Row>

        <Row>
          <Col sm={ 2 } className={ "searchLabel" }>
            Kategori
          </Col>
          <Col sm={ 4 }>
            <Form.Control type="text" name={ "category" } value={ state.category } onChange={ onChangeCallback }/>
          </Col>

          { loggedIn &&
          <>
            <Col sm={ 2 } className={ "searchLabel" }>
              Betyg
            </Col>
            <Col sm={ 4 }>
              <Form.Control name="rating"
                            value={ state.rating }
                            onChange={ onChangeCallback }
                            as="select">
                <option value="" key={ "" }>Alla betyg...</option>
                { [ ...Array(5) ].map((x, i) => (
                  <option key={ i + 1 } value={ i + 1 }>{ i + 1 }</option>
                )) }
              </Form.Control>
            </Col>
          </>
          }
        </Row>

        <Row>
          <Col sm={ 2 } className={ "searchLabel" }>
            Tag
          </Col>
          <Col sm={ 4 }>
            <Form.Control type="text" name={ "tag" } value={ state.tag } onChange={ onChangeCallback }/>
          </Col>

          { loggedIn &&
          <>
            <Col sm={ 2 } className={ "searchLabel" }>
              Övrigt
            </Col>
            <Col sm={ 4 } className={ "searchLabel" }>
              <Form.Check
                type="checkbox"
                label="Vill laga"
                id="wantToTest"
                name="wantToTest"
                checked={ (state.wantToTest === 'true') }
                onChange={ onChangeCallback }
                custom
              />

              <Form.Check
                type="checkbox"
                label="Har lagat"
                id="hasTested"
                name="hasTested"
                checked={ (state.hasTested === 'true') }
                onChange={ onChangeCallback }
                custom
              />
            </Col>
          </>
          }
        </Row>

        <Row>
          <Col sm={ { span: 10, offset: 2 } }>
            <Button type="submit">Sök</Button>
          </Col>
        </Row>
      </Form>

    </div>
  );
}

export default SearchPage;