import React, { useState } from "react";
import { faClock, faExclamationCircle, faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import reactStringReplace from "react-string-replace";
import Badge from "react-bootstrap/Badge";
import Alert from "react-bootstrap/Alert";
import Image from "react-bootstrap/Image";
import { Timer } from "./Timer";

// Note: only one markdown per row is supported normally...
export function RecipeTextParser({ recipe, text }) {
  let replacedText;
  const [ showTimer, setShowTimer ] = useState(false);

  replacedText = replaceInfo(text)
  replacedText = replaceImportant(replacedText)
  replacedText = replaceQuestion(replacedText)
  replacedText = replaceTimers(replacedText, showTimer, setShowTimer);
  replacedText = replaceImage(replacedText, recipe);

  return replacedText;
}

function replaceInfo(text) {
  // Match #(i)
  let replacedText = reactStringReplace(text, /#\(i\)/, (match, i) => (
    <React.Fragment key={ "i1-" + match + i }>
      <FontAwesomeIcon key={ match + i } icon={ faInfoCircle } style={ { color: '#7386D5' } }/>
      { match }
    </React.Fragment>
  ));

  // Match #(i:text)
  replacedText = reactStringReplace(replacedText, /#\(i:(.*)\)/, (match, i) => {
    return (
      <React.Fragment key={ "i2-" + match + i }>
        <Badge variant="primary" style={ { backgroundColor: '#7386D5' } }>
          <FontAwesomeIcon icon={ faInfoCircle }/> { match }
        </Badge>
      </React.Fragment>
    )
  });

  return replacedText;
}

function replaceQuestion(text) {
  // Match #(?)
  let replacedText = reactStringReplace(text, /#\(\?\)/, (match, i) => (
    <React.Fragment key={ "q1-" + match + i }>
      <FontAwesomeIcon icon={ faQuestionCircle } style={ { color: '#ffc107' } }/>
      { match }
    </React.Fragment>
  ));

  // Match #(?:text)
  replacedText = reactStringReplace(replacedText, /#\(\?:(.*)\)/, (match, i) => {
    return (
      <React.Fragment key={ "q2-" + match + i }>
        <Badge variant="warning">
          <FontAwesomeIcon icon={ faQuestionCircle }/> { match }
        </Badge>
      </React.Fragment>
    )
  });

  return replacedText;
}

function replaceImportant(text) {
  // Match #(!)
  let replacedText = reactStringReplace(text, /#\(!\)/, (match, i) => (
    <React.Fragment key={ "!1-" + match + i }>
      <FontAwesomeIcon icon={ faExclamationCircle } style={ { color: 'red' } }/>
      { match }
    </React.Fragment>
  ));

  // Match #(!:text)
  replacedText = reactStringReplace(replacedText, /#\(!:(.*)\)/, (match, i) => {
    return (
      <Alert variant={ "danger" } key={ "!2-" + match + i }>
        <FontAwesomeIcon icon={ faExclamationCircle }/> { match }
      </Alert>
    )
  });

  return replacedText;
}

function replaceTimers(text, showTimer, setShowTimer) {
  // Match #(timer:text)
  return reactStringReplace(text, /#\(timer:(.*)\)/, (match, i) => {
    return (
      <React.Fragment key={ "t1-" + match + i }>
        <FontAwesomeIcon icon={ faClock } style={ { color: 'red' } } onClick={ () => setShowTimer(!showTimer) }/>
        <Timer time={match} show={showTimer} />
      </React.Fragment>
    )
  });
}

function replaceImage(text, recipe) {
  // Match #(image:index)
  return reactStringReplace(text, /#\(image:(.*)\)/, (match, i) => {

    if (isNaN(match)) {
      return <Alert variant={ "danger" } key={ "imageerror1" }>
        Fel: Bilden anges med ett ett heltal (ie. index). Det finns { recipe.images.length } bilder i detta recept.
      </Alert>
    }

    if (match < 1) {
      return <Alert variant={ "danger" } key={ "imageerror1" }>
        Fel: Bilder räknas från 1! Det finns { recipe.images.length } bilder i detta recept.
      </Alert>
    }

    if (recipe.images.length < 1) {
      return <Alert variant={ "danger" } key={ "imageerror1" }>
        Fel: Inga bilder finns i detta recept!
      </Alert>
    }

    let imageIndex = match - 1

    if (imageIndex >= recipe.images.length) {
      return <Alert variant={ "danger" } key={ "image2-" + imageIndex }>
        Fel: Ingen bild hittades med index { match }. Det finns { recipe.images.length } bilder i detta recept.
      </Alert>
    }

    let image = recipe.images[imageIndex]

    return (
      <RecipeImage image={ image } key={ "image3-" + imageIndex }/>
    )
  });
}

function RecipeImage(props) {
  const image = props.image
  const baseUrl = 'https://res.cloudinary.com/dkhktoyb0/image/upload';
  let url = image.url;
  let urlPostfix = url.substring(url.lastIndexOf('/upload/') + 8);
  return <Image className="img-fluid" src={ baseUrl + '/c_fill,w_300,r_20/' + urlPostfix }/>
}