import React from 'react';
import Nav from 'react-bootstrap/Nav';
import {Link} from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDice} from "@fortawesome/free-solid-svg-icons";
import {useRecoilValue} from "recoil";
import {metadataAtom} from "../states/metadata";
import {currentUserAtom} from "../states/auth";
import Spinner from "react-bootstrap/Spinner";

const SideNavbar = () => {
  const metadata = useRecoilValue(metadataAtom);
  const currentUser = useRecoilValue(currentUserAtom);

  if (!metadata) {
    return <Spinner animation="border" variant="primary"/>
  }

  console.log("metadata:", metadata)

  return (
    // .d-print-none -> Don't add it when printing!
    <nav id="sidebar" className={"d-print-none"}>
      <div className="sidebar-header">
        <h3>Familjerecept</h3>
      </div>

      <ul className="list-unstyled components">

        <li>
          <Nav.Link to="/" as={Link}>Hem</Nav.Link>
        </li>

        {currentUser && currentUser.family &&
          <li>
            <Nav.Link to="/family" as={Link}>Familjesidan</Nav.Link>
          </li>
        }

        <li>
          <Nav.Link to="/search" as={Link}>Sök</Nav.Link>
        </li>

        <li>
          <Nav.Link to="/search/random" as={Link}>Slumpgeneratorn <FontAwesomeIcon icon={faDice}/></Nav.Link>
        </li>

        <li>
          <Nav.Link to="/events/recent" as={Link}>Händelser</Nav.Link>
        </li>

        {currentUser &&
          <li>
            <Nav.Link to="/recipe/new" as={Link}>Skapa recept</Nav.Link>
          </li>
        }

        <li>
          <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Kategorier</a>
          <ul className="collapse list-unstyled" id="homeSubmenu">
            {metadata.categories
              .filter(category => {
                return getWeightByCategory(metadata, category) > 0
              }) // Show only those that has some content...
              .map((category) => (
                <li key={category}>
                  <Nav.Link to={'/search?category=' + category} as={Link}>{category} <Badge
                    variant="light">{getWeightByCategory(metadata, category)}</Badge></Nav.Link>
                </li>
              ))}
          </ul>
        </li>

      </ul>

      {/*<div className="tagsNavbar">*/}
      {/*  <h5>Kategorier*/}
      {/*    <Nav.Link to="/cloud/categories" as={ Link }><i className="fa fa-cloud" aria-hidden="true"/></Nav.Link>*/}
      {/*  </h5>*/}

      {/*  { this.props.categories.sort().map((category) => (*/}
      {/*    <Link to={ '/search?category=' + category } as={ Link } style={ { display: 'inline-block' } }>*/}
      {/*      <Badge variant="dark" key={ category } style={{background: '#6d7fcc', fontWeight: '100'}}>*/}
      {/*        { category } <Badge variant="light" style={{fontSize: '75%'}}>{ this.getWeightByCategory(category) }</Badge>*/}
      {/*      </Badge>*/}
      {/*    </Link>*/}
      {/*  )) }*/}
      {/*</div>*/}


      <div className="tagsNavbar">
        <h5>Taggar
          <Nav.Link to="/cloud/tags" as={Link}><i className="fa fa-cloud" aria-hidden="true"/></Nav.Link>
        </h5>

        {/*TODO: used to sort here */}
        {metadata.tags.map((tag) => (
          <Link to={'/search?tag=' + tag} as={Link} style={{display: 'inline-block'}} key={tag}>
            <Badge variant="dark" key={tag} style={{background: '#6d7fcc', fontWeight: '100'}}>
              {tag} <Badge variant="light" style={{fontSize: '75%'}}>{getWeightByTag(metadata, tag)}</Badge>
            </Badge>
          </Link>
        ))}
      </div>

      <ul className="list-unstyled CTAs">
        <li>
          <Nav.Link to="/about" as={Link} className="article">Om</Nav.Link>
        </li>
      </ul>
    </nav>
  );
}

function getWeightByCategory(metadata, category) {
  if (metadata.statistics.categories) {
    let statistics = metadata.statistics.categories.find(s => s.text === category);
    if (statistics) {
      return statistics.count;
    } else {
      return 0
    }
  }
  return 0;
}

function getWeightByTag(metadata, tag) {
  if (metadata.statistics.tags) {
    let statistics = metadata.statistics.tags.find(s => s.text === tag);
    if (statistics) {
      return statistics.count;
    } else {
      return 0
    }
  }
  return 0;
}

export default SideNavbar;