import React, { useEffect, useState } from "react";
import { anonymousAxios } from "../App";
import Card from "react-bootstrap/Card";
import { Carousel } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

const WineRecommendations = ({ ingredient }) => {
  const [ recommendedWines, setRecommendedWines ] = useState([])

  useEffect(() => {
    /*anonymousAxios.get('https://backend.omenstund.se/wines/recommendation/ingredient/sv/' + ingredient).then((response) => {
      console.log("WINES response", response.data)
      setRecommendedWines(response.data)
    });
     */
    setRecommendedWines([])
  }, [ ingredient ]);

  let output;
  if (recommendedWines.length === 0) {
    output = <></> // <Spinner animation="border" variant="primary"/>
  } else {
    output = (
      <Carousel
        controls={ false }
        nextIcon={ <span aria-hidden="true" className="carousel-control-next-icon" style={ { background: 'blue' } }/> }
        prevIcon={ <span aria-hidden="true" className="carousel-control-prev-icon" style={ { background: 'red' } }/> }>

        { recommendedWines.map((wine, index) => (
          <Carousel.Item key={ index }>
            <div style={ { padding: '5px', width: '330px' } }>
              { renderWine(wine) }
            </div>

          </Carousel.Item>
        )) }
      </Carousel>
    );
  }

  return output
}

function renderWine(wine) {
  return (
    <Card key={ "wine" } className={ 'mb-4' }>
      <div className="badge-ribbon" style={ { "--d": "4px", "--w": "100px", "--c": "#ff008c" } }>Vin</div>

      { wine &&
      <Card.Body>
        <Card.Header style={ { textAlign: 'left' } }>
          <a href={ "https://vin.omenstund.se/wineDisplay?wineId=" + wine.wineId } target="_blank" rel="noreferrer">{ wine.name }</a>
        </Card.Header>
        <Card.Text style={ { textAlign: 'left', minHeight: '80px' } }>
          { wine.description }
          ({ wine.color })
        </Card.Text>
        {/*<Button variant="primary">Go somewhere</Button>*/ }
        {/*<footer className="blockquote-footer">*/ }
        {/*  Skapades av <cite title="Source Title">{ recipe.createdBy.alias }</cite>*/ }
        {/*</footer>*/ }
      </Card.Body>
      }
      <Card.Footer style={ { textAlign: 'center' } }>
        <small className="text-muted">Rekommendation från <a href="https://vin.omenstund.se/" target="_blank" rel="noreferrer">VinGrottan</a>!</small>
      </Card.Footer>
    </Card>
  );
}

export default WineRecommendations;