import React from 'react';
import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";
import Table from "react-bootstrap/Table";

const AboutPage = () => {
  return (
    <div>
      <h3>Om Familjerecept</h3>
      <p>
        Familjerecept utvecklas av mig, Lennart Häggkvist. Den används primärt för att jag ska kunna ha ett hobbyprojekt
        där
        jag kan lära mig mer om olika produkter, ramverk & diverse molnbaserade tjänster mm men även för att jag vill ha
        ett ställe där jag kan spara mina recept.
      </p>

      <h3>Tekniker</h3>
      <h4>Bygge och deploy</h4>
      <ul>
        <li>Azure DevOps för git, pipelines etc</li>
        <li>Infrastruktur 'as code' - deployas mha yaml pipelines</li>
        <li>Alla applikationer byggs som Docker containers</li>
        <li>Helm - Kubernetes deploy utility</li>
      </ul>

      <h4>Drift & molntjänster</h4>
      <ul>
        <li>Azure:
          <ul>
            <li>AKS (Kubernetes)</li>
            <li>Container Registry - för alla Docker images</li>
            <li>Key vault - alla hemliga saker sparas där</li>
            <li>Cosmos DB - databasen</li>
            <li>DNS Zone - dns server</li>
            <li>Storage account - file share: backuper etc</li>
          </ul>
        </li>
        <li>Cloudinary - bilder</li>
        <li>SendGrid - för email</li>
        <li>Lets encrypt - certifikat</li>
        <li>Grafana Cloud - för statistik</li>
        <li>Teams - för notifieringar</li>
        <li>I Kubernetes klustret:
          <ul>
            <li>Ingress-nginx - reverse proxy / load balancer for incoming requests</li>
            <li>Keycloak - för authentisering etc. Bla. Google inloggning</li>
            <li>Wordpress - för kul skull</li>
            <li>Mariadb - används av Keycloak och Wordpress</li>
            <li>Cert-manager - certifikathantering</li>
            <li>External-dns - automatisk dns registrering i dns zonerna</li>
          </ul>
        </li>
      </ul>

      <h4>Backend</h4>
      <Table striped bordered hover size="sm">
        <thead>
        <tr>
          <th width={'30px'}>Bibliotek/ramverk</th>
          <th>Beskrivning</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Java 21</td>
          <td></td>
        </tr>
        <tr>
          <td>Spring Boot 3.2</td>
          <td>Behövs en förklaring?</td>
        </tr>
        <tr>
          <td>Lombok</td>
          <td>Så att man behöver skriva mindre java kod :-)</td>
        </tr>
        <tr>
          <td>div bibliotek</td>
          <td>För att prata med CosmosDB, Cloudinary, KeyCloak</td>
        </tr>
        </tbody>
      </Table>

      <h4>Frontend</h4>

      <Table striped bordered hover size="sm">
        <thead>
        <tr>
          <th width={'30px'}>Bibliotek/ramverk</th>
          <th>Beskrivning</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>React</td>
          <td>React. Använde <Nav as={Link} to={"https://github.com/facebook/create-react-app"}
                                  style={{display: 'inline'}}>create-react-app</Nav> för att skapa gränsnittet
          </td>
        </tr>
        <tr>
          <td><Nav as={Link} to={"https://reacttraining.com/react-router/web/guides/quick-start"}>React-router</Nav>
          </td>
          <td>Router hantering för React</td>
        </tr>
        <tr>
          <td>React-bootstrap</td>
          <td>React baserad variannt av Bootstrap</td>
        </tr>
        <tr>
          <td><Nav as={Link} to={"https://bootstrapious.com/p/bootstrap-sidebar"}>bootstrapious</Nav></td>
          <td>Sidebar inspiration</td>
        </tr>
        <tr>
          <td><Nav as={Link} to={"https://react-autosuggest.js.org/"}>React Autosuggest</Nav></td>
          <td>Auto-complete för snabbsöket</td>
        </tr>
        <tr>
          <td>Formik</td>
          <td>Formulärhanterare</td>
        </tr>
        <tr>
          <td><Nav as={Link} to={"https://github.com/axios/axios"}>Axios</Nav></td>
          <td>HTTP Client</td>
        </tr>
        <tr>
          <td><Nav as={Link} to={"https://momentjs.com"}>Momentjs</Nav></td>
          <td>Datumformatering</td>
        </tr>
        <tr>
          <td><Nav as={Link} to={"https://favicon.io/"}>Favicon.io</Nav></td>
          <td>favicon generator</td>
        </tr>
        <tr>
          <td><Nav as={Link} to={"https://fontawesome.com//"}>Fontawesome</Nav></td>
          <td>Icons etc</td>
        </tr>
        <tr>
          <td><Nav as={Link} to="https://react-wordcloud.netlify.app/">React-wordcloud</Nav></td>
          <td>Tag molnet</td>
        </tr>
        <tr>
          <td><Nav as={Link} to="https://github.com/Andarist/react-textarea-autosize">React-textarea-autosize</Nav></td>
          <td>Auto resize textarea</td>
        </tr>
        <tr>
          <td><Nav as={Link} to="https://www.tiny.cloud/">TinyMCE</Nav></td>
          <td>WYSIWYG editor</td>
        </tr>
        <tr>
          <td><Nav as={Link} to="https://reactdatepicker.com/">React-datepicker</Nav></td>
          <td>react-datepicker</td>
        </tr>
        </tbody>
      </Table>


      <h3>Historik</h3>
      <p>
        Receptgrottan var arbetsnamnet länge tills Familjerecept domänen valdes. Tanken var/är att recepten ska bli mer
        orienterade runt familjen.
      </p>
      <p>
        Receptgrottan hade åtminstone tredje större omskrivningen. Det finns mellanvarianter där databasen uppgrades
        osv, men
        grovt så finns följande versioner:
      </p>

      {/* ---------------------------------------------- */}
      <hr/>
      <h4>Version 1</h4>
      <b>År</b>: ~2012<br/>
      <b>Drift</b>: Heroku<br/>
      <b>Tekniker</b>:
      <ul>
        <li>Java ramverk: Play Framework</li>
        <li>Java 7</li>
        <li>Molntjänster:
          <ul>
            <li>Heroku</li>
            <li>Amazon S3 för bilder (Heroku)</li>
            <li>Gmail</li>
            <li>Postgres - först (Heroku)</li>
            <li>MongoDb - senare (Heroku)</li>
          </ul>
        </li>
        <li>Git - Heroku</li>
      </ul>

      {/* ---------------------------------------------- */}
      <hr/>
      <h4>Version 2</h4>
      <b>År</b>: ~2016<br/>
      <b>Drift</b>: Docker swarm på egen dator (inuc)<br/>
      <b>Tekniker</b>:
      <ul>
        <li>Java ramverk: JHipster (ca version 2 - 4), som bla använder:
          <ul>
            <li>Spring boot 1.5</li>
            <li>Angular</li>
            <li>Bootstrap</li>
          </ul>
        </li>
        <li>Java 8</li>
        <li>MongoDb (körandes i Docker Swarm)</li>
        <li>Molntjänster:
          <ul>
            <li>Cloudinary - bilder</li>
            <li>Gmail</li>
          </ul>
        </li>
        <li>Git - Bitbucket</li>
      </ul>

      {/* ---------------------------------------------- */}
      <hr/>
      <h4>Version 3</h4>
      <b>År</b>: 2019<br/>
      <b>Drift</b>: Azure & Kubernetes<br/>
      <b>Tekniker</b>: Se rubrik Tekniker för detaljer, men för jämförelsen:
      <ul>
        <li>Java ramverk: Spring boot</li>
        <li>Molntjänster:
          <ul>
            <li>Azure CosmosDB - databas</li>
            <li>Kubernetes (AKS)</li>
            <li>Cloudinary - bilder</li>
            <li>Gmail</li>
          </ul>
        </li>
        <li>Git - Azure DevOps</li>
      </ul>


    </div>
  )
}

export default AboutPage;