import React from "react";
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Rating({ rating }) {
  if (!rating) {
    return <></>
  }

  const items = []
  for (let i = 1; i <= rating; i++) {
    items.push(<FontAwesomeIcon icon={ faStar } style={ { color: 'black' } } key={ i }/>)
  }

  return (
    <>{ items }</>
  )
}
