import axios from 'axios';
import {toast} from "react-toastify";
import {keycloak} from "../App";
import {handleHeaderMessages} from "../utils/headerMessage";

const TIMEOUT = 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = window.ENV.BACKEND_HOST;

const setupAxiosInterceptors = () => {
  const onRequestSuccess = config => {
    const token = keycloak.token
    // const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };
  const onResponseSuccess = response => response;
  const onResponseError = err => {
    console.log("err", err)
    if (err.code === "ERR_NETWORK") {
      toast.error("Nätverksfel, försök igen.");
      return;
    }
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 403 || status === 401) {
      // onUnauthenticated();
      toast.error("Måste vara inloggad för att göra anropet: " + status)
    } else {
      handleHeaderMessages(err.response.headers)

      // Special case if we don't get any error code from backend,,,
      let headerMessages = err.response.headers['x-atdev-messages'];
      if(headerMessages) {
        let messageList = JSON.parse(headerMessages)
        if (messageList.length === 0) {
          toast.error("Ett fel uppstod. Http kod " + status)
        }
      }
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
