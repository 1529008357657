import React, {useEffect, useState} from "react";
import {RecipeViewDetail} from "./RecipeViewDetail";
import {useParams} from "react-router-dom";
import axios from "axios";
import {useRecoilValue} from "recoil";
import {loggedInAtom} from "../../states/auth";
import UserRecipeToolbar from "../../components/UserRecipeToolbar";

const RecipeViewPage = () => {
  let {uriPath} = useParams();
  const loggedIn = useRecoilValue(loggedInAtom);

  const [recipe, setRecipe] = useState(null)

  useEffect(() => {
    axios.get("/api/recipes/uriPath/" + uriPath)
      .then(function (response) {
        console.log("Get recipe success:", response.data);
        setRecipe(response.data)
      });
  }, [axios]);

  /*if (this.props.isLoading) {
    return <Spinner animation="border" variant="primary"/>
  }*/

  if (recipe) {
    return (
      <div>
        {loggedIn &&
          <UserRecipeToolbar recipe={recipe}/>
        }
        <Recipe recipe={recipe}/>
      </div>)
  }
}

function Recipe({recipe}) {
  return (
    <RecipeViewDetail recipe={recipe}/>
  );
}

export default RecipeViewPage;