import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Button, Col, Form, InputGroup, Row, Table} from "react-bootstrap";
import * as yup from "yup";
import {Formik} from "formik";
import ListGroup from 'react-bootstrap/ListGroup'
import {handleHeaderMessages} from "../utils/headerMessage";
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {currentUserAtom} from "../states/auth";

const FamilyPage = () => {
  const navigate = useNavigate()
  const history = useNavigate();

  const currentUser = useRecoilValue(currentUserAtom);
  const [familyData, setFamilyData] = useState(null)
  const [invitations, setInvitations] = useState(null)

  useEffect(() => {
    console.log("LOADING famly: ", currentUser)
    axios.get("/api/families/" + currentUser.family.id)
      .then((response) => {
        console.log("famly", response);
        setFamilyData(response.data);
        setInvitations(response.data.invitations)
      })
      .catch(console.log)
  }, [currentUser])

  return (
    <div>
      <h3>Familjesidan</h3>

      {familyData &&
        <FamilyPageContent
          familyData={familyData}
          invitations={invitations}
          setInvitations={setInvitations}
        />
      }

    </div>
  );
}

const schema = yup.object().shape({
  familyName: yup.string()
    .min(2, "minst 2 tecken krävs")
    .required("Familjenamn krävs"),
  invitationCode: yup.string()
    .min(5, "minst 5 tecken krävs")
    .required("Inbjudningskod krävs"),
  newInvitationEmail: yup.string()
    .email("Måste vara en giltid emailadress")
});

const FamilyPageContent = ({familyData, invitations, setInvitations}) => {
  const navigate = useNavigate()

  const handleSubmit = (event) => {
    let postInvitations = invitations;

    // Ugly fix to add the email address that the user forgot to click "+" on...
    if (event.newInvitationEmail !== "") {
      addInvitation(event.newInvitationEmail)
      postInvitations = ([...postInvitations,
          {
            email: event.newInvitationEmail
          }]
      );
      event.newInvitationEmail = ""
    }

    let body = {
      "name": event.familyName,
      "invitationCode": event.invitationCode,
      "invitations": postInvitations
    }
    console.log("POST update family:", body)
    axios.put("api/families/" + familyData.id, body)
      .then((response) => {
        console.log("response", response)

        handleHeaderMessages(response.headers)

        navigate("/family")
      });
  };

  /*

                      <div>
                      <Form.Check type="checkbox" label="Administrator" checked={true}/>
                      <Button variant="danger" size="sm" className="float-right" disabled={true}>X ta bort</Button>
                    </div>

   */

  function addInvitation(email) {
    if (email) {
      setInvitations([...invitations,
        {
          email: email
        }]
      )
    }
  }

  function removeInvitation(index, email) {
    console.log("REMOVE", index, email)
    let updated = invitations.splice(index, 1)
    console.log("XX updated", updated)
    console.log("XX invitations", invitations)
    setInvitations([...invitations]);
  }

  return <>
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        familyName: familyData.name,
        invitationCode: familyData.invitationCode,
        newInvitationEmail: ""
      }}
    >
      {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
        <Form noValidate onSubmit={handleSubmit}>

          <Form.Group as={Row} className="mb-3" controlId="validationFormikFamilyName">
            <Form.Label column sm={3}>Familjenamn</Form.Label>
            <Col>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="familyName"
                  aria-describedby="inputGroupPrepend"
                  name="familyName"
                  value={values.familyName}
                  onChange={handleChange}
                  isInvalid={!!errors.familyName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.familyName}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="validationFormikInvitationCode">
            <Form.Label column sm={3}>Inbjudningskod</Form.Label>
            <Col>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  placeholder=""
                  aria-describedby="inputGroupPrepend"
                  name="invitationCode"
                  value={values.invitationCode}
                  onChange={handleChange}
                  isInvalid={!!errors.invitationCode}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.invitationCode}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="validationFormMembers">
            <Form.Label column sm={3}>Medlemmar</Form.Label>
            <Col>
              <ListGroup>
                {familyData.members.map((member, index) => (
                  <ListGroup.Item key={index}>
                    <div>{member.user.name}</div>
                    { /* <Form.Check type="checkbox" label="Administrator" disabled checked={member.administrator} /> */}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Form.Group>

          <Row>
            <Form.Label column sm={3}>Inbjudningar<br/>
              (emailadresser)</Form.Label>

            <Col sm={9}>
              <Table borderless hover size="sm">
                <tbody>
                {invitations && invitations.map((invite, index) => (
                  <tr key={index}>
                    <td>{invite.email}</td>
                    <td><Button
                      onClick={() => {
                        removeInvitation(index, invite.email);
                      }}
                      variant="danger" size="sm" className="float-right">-</Button></td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        placeholder="emailadress till familjemedlem"
                        aria-describedby="inputGroupPrepend"
                        name="newInvitationEmail"
                        value={values.newInvitationEmail}
                        onChange={handleChange}
                        isInvalid={!!errors.newInvitationEmail}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.newInvitationEmail}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </td>
                  <td><Button onClick={() => {
                    addInvitation(values.newInvitationEmail);
                    values.newInvitationEmail = ""
                  }}
                              variant="success" size="sm" className="float-right">+</Button></td>
                </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <Form.Group as={Row} className="mb-3">
            <Col sm={{span: 10, offset: 3}}>
              <Button type="submit">Spara</Button>
            </Col>
          </Form.Group>

        </Form>
      )}
    </Formik>

  </>;
}

export default FamilyPage;