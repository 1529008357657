import React, {useEffect, useState} from 'react';
import './App.css';
import './styles/autosuggest.css';
import 'react-toastify/dist/ReactToastify.css';

import {BrowserRouter as Router} from "react-router-dom";
import AppRouter from "./routes/AppRouter";
import Keycloak from "keycloak-js";
import {ToastContainer} from "react-toastify";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import setupAxiosInterceptors from "./config/axios-interceptor";
import Helmet from "react-helmet";
import NoSleepJs from "nosleep.js";
import axios from "axios";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {currentUserAtom, loggedInAtom} from "./states/auth";

export const keycloak = new Keycloak({
  realm: window.ENV.KEYCLOAK_REALM,
  url: window.ENV.KEYCLOAK_HOST,
  clientId: 'familjerecept-frontend',
  checkLoginIframe: false,
})

const keycloakProviderInitConfig = {
  onLoad: 'check-sso',  // check-sso | login-required
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  checkLoginIframe: false
}

let keycloakInitialized=false; // Keycloak only wants to be initialized once - and react seem to re-evaluate everything...

setupAxiosInterceptors();

// WineCave integrations needs this
export const anonymousAxios = axios.create()

// Global instance (is this really needed?)
export const windowNoSleep = new NoSleepJs();

const App = () => {
  const setCurrentUser = useSetRecoilState(currentUserAtom);
  const setIsLoggedIn = useSetRecoilState(loggedInAtom);
  const [ hasCheckedAuthentication, setHasCheckedAuthentication ] = useState(false)

  if (!keycloakInitialized) {
    keycloak.init(keycloakProviderInitConfig).then((authenticated) => {
      if (authenticated) {
        console.log('User is authenticated');

        axios.get('/api/user').then((response) => {
          console.log("setCurrentUser", response.data)
          setCurrentUser(response.data);
          setIsLoggedIn(true);
          setHasCheckedAuthentication(true);
        });
      } else {
        console.log('User is not authenticated');
        setHasCheckedAuthentication(true);
      }
    });
    keycloakInitialized = true;
  }

  return (
      <React.Suspense fallback={<div>Loading...</div>}>
        <Helmet>
          <title>Familjerecept - ett ställe för glada programmerare med matlagningsintresse</title>
        </Helmet>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <DndProvider backend={HTML5Backend}>
          <Router>
            {/* Check, or i.e. make sure that we know if the user is authenticated or not.
                Otherwise, sub pages could do requests to the backend without Authorization bearer
            */}
            {!hasCheckedAuthentication &&
              <div>Loading...</div>
            }
            {hasCheckedAuthentication &&
              <AppRouter/>
            }
          </Router>
        </DndProvider>
      </React.Suspense>
  );
}

export default App