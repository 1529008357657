import React from "react";
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Heart({ favourite }) {
  if (favourite) {
    return <FontAwesomeIcon icon={ faHeart } style={ { color: 'red' } }/>
  }

  return (
    <></>
  )
}
