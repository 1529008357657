import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import './Tags.css'

export class Tags extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tags: props.tags.sort()
    }
  }

  render() {
    if (!this.state.tags) {
      return;
    }

    let tags = this.state.tags;

    if (tags.length === 0) {
      // Let it contain at least something so that the page renders the same with or without tags
      return <>&nbsp;</>
    }

    return <span>{ tags.map((tag, i) => [
      i > 0 && ", ",
      <Tag key={ tag } tag={ tag }/>
    ]) }</span>

    // return
    //   <Nav className="justify-content-center">
    // { this.state.tags.map(tag => (
    //     <Nav.Item key={ tag } >
    //       <Nav.Link to={ '/search?tag=' + tag } as={ Link }>{ tag }</Nav.Link>
    //     </Nav.Item>
    //   )) }
    // </Nav>
  }
}

function Tag(props) {
  // return <span>{ props.tag }</span>
  return <Nav.Link className={"tagLink"} to={ '/search?tag=' + props.tag } as={ Link }>{ props.tag }</Nav.Link>
}

export default Tags;