

/**
 * Assume/supports "4 portions" or "4" or "24 st" or even "ca 50 st" !
 */
export function parseServingsText(servingsString) {
  if (!servingsString) {
    return
  }
  // regexp to the rescue!
  let groups = servingsString.match(/(\D*)(\d+)(.*)/)

  // Want it as number and not string!
  let numberOfServings = parseInt(groups[2])

  if (groups) {
    return {
      "prefix": groups[1],
      "numberOfServings": numberOfServings,
      "postfix": groups[3]
    }
  }
  throw new Error('no regexp groups found in string "' + servingsString + '"!');
}

