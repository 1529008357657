import React from 'react';

const PageNotFound = () => {
  return (
    <div>
      <h1>Not found</h1>

      Its not you, its us...
    </div>
  );
}

export default PageNotFound;

