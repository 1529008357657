// Render Prop
import React, {useCallback, useEffect, useState} from 'react'
import {Field, Formik} from 'formik'
import {Link, useNavigate} from "react-router-dom"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import * as yup from 'yup'
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import {ReactTags} from 'react-tag-autocomplete'
import '../../pages/recipe-tag.css'
import TextareaAutosize from 'react-textarea-autosize'
import {Editor} from "@tinymce/tinymce-react/lib/cjs/main/ts";
import NavLink from "react-bootstrap/NavLink";
import axios from 'axios';
import Badge from "react-bootstrap/Badge";
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import './RecipeForm.css';
import {useRecoilValue} from "recoil";
import {metadataAtom} from "../../states/metadata";
import {Popover} from "react-bootstrap";

const schema = yup.object({
  name: yup.string()
    .min(5, "minst 5 tecken krävs")
    .required("Namn krävs"),

  category: yup.string()
    .required("Kategori krävs"),

  servings: yup.string()
    .optional().nullable(),

  cookingTime: yup.string()
    .required("Tid krävs"),

  ingredientsString: yup.string()
    .optional(),

  stepsString: yup.string()
    .optional(),

  sourceName: yup.string().when("sourceUrl",
    {
      is: (val) => val,
      then: schema => schema.required("Källnamn krävs"),
      otherwise: schema => schema.optional().nullable()
    }),
  sourceUrl: yup.string()
    .optional().nullable(),

  otherInformation: yup.string()
    .optional().nullable(),

  bump: yup.boolean()
    .default(false)
})

// TODO: extract Tag to own component?
export const RecipeForm = ({recipe, onSubmitFunction, metaDataRefresher}) => {
  let navigate = useNavigate()
  const metadata = useRecoilValue(metadataAtom);

  console.log("RecipeForm", recipe)

  // Set by create or edit page
  const [recipeIngredientGroups, setRecipeIngredientGroups] = useState([]);
  const [recipeIngredientText, setRecipeIngredientText] = useState(recipe.ingredientsAsString)

  const tagSuggestionState = useState([])
  const [, setSuggestions] = tagSuggestionState
  const tagState = useState([])
  const [tags, setTags] = tagState

  useEffect(() => {
    console.log("Fppp", metadata)
    setSuggestions(metadata.tags.map((tag) => {
      return {
        value: tag,
        label: tag
      }
    }))
  }, [metadata, setSuggestions])

  useEffect(() => {
    console.log("recipe.tags", recipe.tags)
    setTags(recipe?.tags?.map((tag) => {
      return {
        value: tag,
        label: tag
      }
    }))
  }, [recipe, setTags])

  useEffect(() => {
    let body = {
      "text": recipeIngredientText
    }
    axios.post("/api/recipe/parser/ingredients", body)
      .then((response) => {
        setRecipeIngredientGroups(response.data.groups)
      })
      .catch(console.log)
  }, [recipeIngredientText])

  // Need to convert ingredients (list) to a string ...
  let initialValues = {
    ...recipe,
    ingredientsString: recipe.ingredientsAsString,
    stepsString: recipe.stepsAsString,
    sourceName: recipe.source?.name,
    sourceUrl: recipe.source?.url,
  }

  return (
    <div className={'recipeDetails'} style={{textAlign: 'left'}}>
      <h4>{recipe.id == null ? "Skapa recept" : "Uppdatera recept"}</h4>
      {recipe.id &&
        <Row><NavLink to={"/recipe/" + recipe.uriPath} as={Link}><i className="fa fa-backward"
                                                                    aria-hidden="true"/>&nbsp; Tillbaka till
          receptet</NavLink></Row>
      }
      <Formik
        validationSchema={schema}
        onSubmit={(values, actions) => onSubmitFunction(navigate, recipe, values, actions, tags, metaDataRefresher)}
        initialValues={initialValues}
      >
        {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => {
          return (<RecipeFormForm
            recipe={recipe}
            handleSubmit={handleSubmit}
            values={values}
            metadata={metadata}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
            tagSuggestionState={tagSuggestionState}
            tagState={tagState}
            recipeIngredientText={recipeIngredientText}
            setRecipeIngredientText={setRecipeIngredientText}
            recipeIngredientGroups={recipeIngredientGroups}
          />)
        }}
      </Formik>
    </div>
  )
}

function renderRecipeIngredientGroups(recipeIngredientGroups) {
  return <div className={"recipeIngredientGroupsEdit"}>
    {recipeIngredientGroups.map((group, index) => (
      <div key={index}>
        {group.header &&
          <Badge key="header" variant="success">{group.header}</Badge>
        }
        {group.rows.map((row, rowIndex) => (
          <div key={rowIndex}>
            <Badge variant="primary">{row.quantity}</Badge>
            <Badge variant="secondary">{row.unit}</Badge>
            <Badge variant="info">{row.ingredient}</Badge>
            <br/>
          </div>
        ))}
      </div>
    ))}
  </div>

  // return <table border={0}>
  //   { recipeIngredientGroups.map((group, index) => (
  //     <>
  //       { group.header &&
  //       <tr>
  //         <th colSpan={ 3 }>{ group.header }</th>
  //       </tr>
  //       }
  //       { group.rows.map((row, rowIndex) => (
  //         <tr>
  //           <td>{ row.quantity }</td>
  //           <td><i>{ row.unit }</i></td>
  //           <td>{ row.ingredient }</td>
  //         </tr>
  //         )) }
  //     </>
  //   )) }
  // </table>
}

const RecipeFormForm = ({
                          recipe,
                          handleSubmit,
                          values,
                          metadata,
                          handleChange,
                          touched,
                          errors,
                          tagSuggestionState,
                          tagState,
                          recipeIngredientText,
                          setRecipeIngredientText,
                          recipeIngredientGroups
                        }) => {

  const [tags, setTags] = tagState

  const [suggestions, setSuggestions] = useState([])

  useEffect(() => {
    setSuggestions(metadata.tags.map((tag) => {
      return {
        value: tag,
        label: tag
      }
    }))
  }, [metadata, setSuggestions])

  const onAdd = useCallback(
    (newTag) => {
      setTags([...tags, newTag])
    }, [tags]
  )

  const onDelete = useCallback(
    (tagIndex) => {
      setTags(tags.filter((_, i) => i !== tagIndex))
    }, [tags]
  )
  /*
  const addTag = (tag) => {
    setTags([
      ...tags,
      {
        label: tag.name,
        value: tag.name
      }
    ])
  }

  const deleteTag = (i) => {
    const tmpTags = tags.slice(0)
    tmpTags.splice(i, 1)
    setTags(tmpTags)
  }
*/
  const handleIngredientChange = (event) => {
    let value = event.target.value;
    setRecipeIngredientText(value)
    // Pass the event to formik
    handleChange(event);
  }

  return <Form noValidate onSubmit={handleSubmit}>

    <Form.Group as={Row} controlId="validationFormikName">
      <Form.Label column sm={2}>Namn</Form.Label>
      <Col sm={10}>
        <Form.Control
          type="text"
          name="name"
          value={values.name}
          onChange={handleChange}
          isValid={touched.name && !errors.name}
          isInvalid={!!errors.name}
        />
        <Form.Control.Feedback type="invalid">
          {errors.name}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="validationFormikCategory">
      <Form.Label column sm={2}>Kategori</Form.Label>
      <Col sm={10}>
        <Form.Control name="category"
                      value={values.category || ""}
                      onChange={handleChange}
                      isValid={touched.category && !errors.category}
                      isInvalid={!!errors.category}
                      as="select">
          <option disabled value="" key={""}>Välj en kategori...</option>
          {metadata.categories.map((category) => (
            <option key={category} value={category}>{category}</option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.category}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="validationFormikTags">
      <Form.Label column sm={2}>Taggar</Form.Label>
      <Col sm={10}>
        <ReactTags
          selected={tags}
          suggestions={suggestions}
          onAdd={onAdd}
          onDelete={onDelete}
          labelText="Välj taggar"
          placeholderText="Lägg till en tag"
          noOptionsText="Inga valda XXX"/>

        <Form.Control.Feedback type="invalid">
          {errors.tags}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="validationFormikDescription">
      <Form.Label column sm={2}>Beskrivning</Form.Label>
      <Col sm={10}>
        <TextareaAutosize name="description"
                          rows={2}
                          defaultValue={values.description}
                          onChange={handleChange}
                          style={{width: "100%"}}
                          className="form-control"/>
        <Form.Control.Feedback type="invalid">
          {errors.description}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="validationFormikCookingTime">
      <Form.Label column sm={2}>Tid</Form.Label>
      <Col sm={10}>
        <Form.Control name="cookingTime"
                      value={values.cookingTime || ""}
                      onChange={handleChange}
                      isValid={touched.cookingTime && !errors.cookingTime}
                      isInvalid={!!errors.cookingTime}
                      as="select">
          <option disabled value="" key={""}>Välj en tid...</option>
          {metadata.cookingTimes.map((time) => (
            <option key={time} value={time}>{time}</option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.cookingTime}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="validationFormikServings">
      <Form.Label column sm={2}>
        Portioner
        <OverlayTrigger trigger="click" placement="top" overlay={(
          <Popover id="popoverHelpServings"
                   title={"Portioner"}>
            Stödjer exempelvis följande format:
            <ul>
              <li>4</li>
              <li>4 portioner</li>
              <li>ca 50 st</li>
            </ul>
          </Popover>
        )}>
          <FontAwesomeIcon icon={faQuestionCircle} id="popoverHelp"/>
        </OverlayTrigger>
      </Form.Label>
      <Col sm={10}>
        <Form.Control
          type="text"
          name="servings"
          value={values.servings || ''}
          onChange={handleChange}
          isValid={touched.servings && !errors.servings}
          isInvalid={!!errors.servings}
        />
        <Form.Control.Feedback type="invalid">
          {errors.servings}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="validationFormikIngredientsString">
      <Form.Label column sm={2}>
        Ingredienser
        <OverlayTrigger trigger="click" placement="top" overlay={(
          <Popover id="popoverHelpIngredients" title={"Ingredienser"}>
            <div>
              <li>Rubriker: <b># Rubrik</b></li>
              <li>Nytt stycke: tom rad</li>
            </div>
          </Popover>
        )}>
          <FontAwesomeIcon icon={faQuestionCircle} id="popoverHelp"/>
        </OverlayTrigger>
      </Form.Label>
      <Col sm={5}>
        <TextareaAutosize name="ingredientsString"
                          rows={3}
                          defaultValue={recipeIngredientText}
                          onChange={handleIngredientChange}
                          style={{width: "100%"}}
                          className="form-control"
                          placeholder="Exempel:
4 st äpplen
5 msk socker
#Detta är en rubrik
Se ? för fler exempel"/>
        <Form.Control.Feedback type="invalid">
          {errors.ingredientsAsStringString}
        </Form.Control.Feedback>
      </Col>
      <Col sm={5}>
        {renderRecipeIngredientGroups(recipeIngredientGroups)}
      </Col>
    </Form.Group>

    {/* TODO: should be a better way handling this if the user got a small screen (ie. difficult to see soft line breaks vs hard ones  */}
    <Form.Group as={Row} controlId="validationFormikStepsString">
      <Form.Label column sm={2}>
        Instruktioner
        <OverlayTrigger trigger="click" placement="top" overlay={(
          <Popover id="popoverHelpInstructions" title={"Instruktioner"}>
            <div>
              <li>Rubriker: <b># Rubrik</b></li>
              <li>Nytt stycke: tom rad</li>
              <li>Stödjer följande egna markups:</li>
              <ul>
                <li><b>#(timer:XhYm)</b> för timer. Exempel:
                  <ul>
                    <li>#(timer:25m)</li>
                    <li>#(timer:3h30m)</li>
                  </ul></li>
                <li><b>#(i)</b> eller <b>#(i:text)</b> för information</li>
                <li><b>#(?)</b> eller <b>#(?:text)</b> för frågetecken</li>
                <li><b>#(!)</b> eller <b>#(!:text)</b> för viktig information</li>
                <li><b>#(image:x)</b> för att inkludera bilder. X = index på bilden</li>
              </ul>
            </div>
          </Popover>
        )}>
          <FontAwesomeIcon icon={faQuestionCircle} id="popoverHelp"/>
        </OverlayTrigger>
      </Form.Label>
      <Col sm={10}>
        <TextareaAutosize name="stepsString"
                          rows={3}
                          defaultValue={values.stepsString}
                          onChange={handleChange}
                          style={{width: "100%"}}
                          className="form-control"/>
        <Form.Control.Feedback type="invalid">
          {errors.stepsAsStringString}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="validationFormikSource">
      <Form.Label column sm={2}>Källa</Form.Label>
      <Col sm={10}>
        <Form.Group as={Row} controlId="formPlaintextSourceName">
          <Form.Label column sm={2}>Namn</Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              name="sourceName"
              value={values.sourceName}
              onChange={handleChange}
              isValid={touched.sourceName && !errors.sourceName}
              isInvalid={!!errors.sourceName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.sourceName}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formPlaintextSourceUrl">
          <Form.Label column sm={2}>Url</Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              name="sourceUrl"
              value={values.sourceUrl}
              onChange={handleChange}
              isValid={touched.sourceUrl && !errors.sourceUrl}
              isInvalid={!!errors.sourceUrl}
            />
            <Form.Control.Feedback type="invalid">
              {errors.sourceUrl}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="validationFormikOtherInformation">
      <Form.Label column sm={2}>Övrigt</Form.Label>
      <Col sm={10}>
        <Field
          name="otherInformation">
          {({field, form, meta}) =>
            <Editor
              textareaName="otherInformation"
              apiKey="eu5kbtumc6u03xfgve3evhllp0fg7n9wosz78n67kleuxoqt"
              value={values.otherInformation}
              onEditorChange={(content, editor) => {
                form.setFieldValue("otherInformation", content, true);
                form.setFieldTouched("otherInformation", true, false);
              }
              }
              init={{
                height: 200,
                menubar: false,
                plugins: [
                  'lists autoresize code'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic | bullist numlist | removeformat code | help',
                max_height: 500
              }}
            />
          }
        </Field>
        <Form.Control.Feedback type="invalid">
          {errors.otherInformation}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="validationFormikWineRecommendationCategory">
      <Form.Label column sm={2}>Vinreko.
        <OverlayTrigger trigger="click" placement="top" overlay={(
          <Popover id="popoverHelpInstructions" title={"Vingrottan"}>
            <div>
              Kategorin används av Vingrottan för att kunna rekommendera ett vin.
            </div>
          </Popover>
        )}>
          <FontAwesomeIcon icon={faQuestionCircle} id="popoverHelp"/>
        </OverlayTrigger>
      </Form.Label>
      <Col sm={10}>
        <Form.Control name="wineRecommendationCategory"
                      value={values.wineRecommendationCategory || ""}
                      onChange={handleChange}
                      isValid={touched.wineRecommendationCategory && !errors.wineRecommendationCategory}
                      isInvalid={!!errors.wineRecommendationCategory}
                      as="select">
          <option value="" key={""}>Ingen vinrekommendation</option>
          {metadata.wineRecommendationCategories.map((category) => (
            <option key={category} value={category}>{category}</option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.wineRecommendationCategory}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>

    {recipe.id &&
      <Form.Group as={Row} controlId="validationFormikBump">
        <Form.Label column sm={2}>&nbsp;</Form.Label>
        <Col sm={10}>
          <Form.Check name="bump"
                      type="checkbox"
                      label="Visa receptet först på hemsidan"
                      onChange={handleChange}
                      isValid={touched.bump && !errors.bump}
                      isInvalid={!!errors.bump}
          />
          <Form.Control.Feedback type="invalid">
            {errors.bump}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    }

    <Row>
      <Col sm={2}>&nbsp;</Col>
      <Col>
        {recipe.id &&
          <Button type="submit" className={"text-center"}>Uppdatera receptet</Button>
        }
        {!recipe.id &&
          <Button type="submit" className={"text-center"}>Skapa recept</Button>
        }
      </Col>
    </Row>

  </Form>
}

export default RecipeForm