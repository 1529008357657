import Modal from "react-bootstrap/Modal";
import * as yup from "yup";
import {Button, Form} from "react-bootstrap";
import {Formik} from "formik";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {handleHeaderMessages} from "../utils/headerMessage";

const schema = yup.object().shape({
  familyName: yup.string()
      .min(2, "minst 2 tecken krävs")
      .required("Familjenamn krävs"),
    invitationCode: yup.string()
        .min(5, "minst 5 tecken krävs")
        .required("Kod krävs")
});

function CreateFamilyDialog({showModal, setShowModal, familyName, familyId}) {
  let navigate = useNavigate();

  const handleClose = () => setShowModal(false);

  const handleSubmit = (event) => {
    let familyName = event.familyName;
    let invitationCode = event.invitationCode;
    let body = {
      "familyId": familyId,
      "name": familyName,
      "invitationCode": invitationCode
    }
    console.log("POST join family:", body)
    axios.post("api/families/join", body)
        .then((response) => {
          console.log("response", response)

          handleHeaderMessages(response.headers)

          navigate("/family")
        });
  };

  return (
      <>
        <Modal show={showModal} onHide={handleClose}>
          <Formik
              validationSchema={schema}
              onSubmit={handleSubmit}
              initialValues={{
                familyName: familyName
              }}
          >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>

                  <Modal.Header closeButton>
                    <Modal.Title>Gå med i familj</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                    <Form.Group controlId="validationFormik03">
                        <Form.Label>Namn</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="familyName"
                          value={values.familyName}
                          onChange={handleChange}
                          isInvalid={!!errors.familyName}
                          readOnly
                        />

                        <Form.Control.Feedback type="invalid">
                        {errors.familyName}
                        </Form.Control.Feedback>
                    </Form.Group>

                      <Form.Group controlId="validationFormik04">
                          <Form.Label>Kod</Form.Label>
                          <Form.Control
                              type="text"
                              placeholder=""
                              name="invitationCode"
                              value={values.invitationCode}
                              onChange={handleChange}
                              isInvalid={!!errors.invitationCode}
                          />

                          <Form.Control.Feedback type="invalid">
                              {errors.invitationCode}
                          </Form.Control.Feedback>
                      </Form.Group>

                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Avbryt
                    </Button>
                    <Button type="submit">
                      Gå med i familj
                    </Button>
                  </Modal.Footer>
                </Form>
            )}
          </Formik>
        </Modal>
      </>
  );
}

export default CreateFamilyDialog;

