import React, { useState } from 'react';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Tags from "../../components/Tags";
import Image from "react-bootstrap/Image";
import { AuditingUserInformation } from "../../components/AuditingUserInformation";
import DOMPurify from 'dompurify';
import Table from "react-bootstrap/Table";
import { Rating } from "../../components/Rating";
import Carousel from "react-bootstrap/Carousel";
import { RecipeTextParser } from "../../components/RecipeTextParser";
import Helmet from "react-helmet";
import Form from "react-bootstrap/Form";
import { Decimal } from 'decimal.js';
import { parseServingsText } from "./RecipeFunctions";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import WineRecommendations from "../../components/WineRecommendations";

export const RecipeViewDetail = (props) => {

  const recipe = props.recipe

  let initialNumberOfServings = 0
  let servingsInfo

  if (recipe.servings) {
    servingsInfo = parseServingsText(recipe.servings)
    initialNumberOfServings = servingsInfo.numberOfServings
  }
  let [ selectedNumberOfPortions, setSelectedNumberOfPortions ] = useState(initialNumberOfServings)

  function renderRecipe(recipe) {
    return (
      <div className={ 'recipeDetails' } style={ { textAlign: 'left' } }>
        <Helmet>
          <title>Familjerecept: { recipe.name }</title>
        </Helmet>
        <Container fluid>
          <Row>
            <Col md={ 7 } lg={ 7 } xl={ 5 }>
              <Row><h2>{ recipe.name }</h2></Row>
              <Row>
                { recipe.description }
              </Row>
              <Row style={ { paddingTop: '20px' } }/>
              <Row>
                <table>
                  <tbody>
                  <tr>
                    <th scope="row" style={ { width: '130px' } }><i className="fa fa-object-group" aria-hidden="true"/>Kategori</th>
                    <td>{ recipe.category }</td>
                  </tr>
                  { recipe.cookingTime &&
                  <tr>
                    <th scope="row"><i className="fa fa-clock" aria-hidden="true"/>Tid</th>
                    <td>{ recipe.cookingTime }</td>
                  </tr>
                  }
                  { recipe.tags && recipe.tags.length > 0 &&
                  <tr>
                    <th scope="row"><i className="fa fa-tags" aria-hidden="true"/>Taggar</th>
                    <td><Tags tags={ recipe.tags }/></td>
                  </tr>
                  }
                  { recipe.source && recipe.source.name &&
                  <tr>
                    <th scope="row"><i className="fas fa-location-arrow" aria-hidden="true"/>Källa</th>
                    <td>{ renderSource(recipe.source) }</td>
                  </tr>
                  }
                  { recipe.servings ?
                    (<tr>
                      <th scope="row"><i className="fa fa-user" aria-hidden="true"/></th>
                      <td>
                        <ServingsSelect/>
                      </td>
                    </tr>) : null
                  }
                  </tbody>
                </table>
              </Row>
            </Col>
            <Col>
              { renderImages(recipe.images) }

              <div className="float-right">
                <CopyToClipboard onCopy={
                  function () {
                    toast.info("Receptets URL har kopierats till \"urklipp\"")
                  }
                } text={ "https://open.familjerecept.nu/share/" + recipe.uriPath }>
                  <Button variant="outline-primary" size={ "sm" } style={ { marginTop: "2px" } }>Dela receptet</Button>
                </CopyToClipboard>
              </div>

            </Col>
          </Row>

          <Row style={ { paddingTop: '20px' } }/>

          { recipe.ingredientGroups && recipe.ingredientGroups.length > 0 &&
          <>
            <Row>
              <h4>Ingredienser</h4>
            </Row>
            <Row>
              <IngredientGroups ingredientGroups={ recipe.ingredientGroups } selectedPortions={ selectedNumberOfPortions }/>
            </Row>
          </>
          }

          { recipe.stepGroups && recipe.stepGroups.length > 0 &&
          <>
            <Row>
              <h4>Tillagning</h4>
            </Row>
            { recipe.servings && selectedNumberOfPortions !== servingsInfo.numberOfServings &&
            <Row>
              <Alert variant="danger">
                Obs! Tid och/eller temperatur kan behöva ändras då antalet portioner har ändrats!
              </Alert>
            </Row>
            }
            <Row>
              <StepGroups stepGroups={ recipe.stepGroups }/>
            </Row>
          </>
          }

          { recipe.otherInformation &&
          <Row>
            <Col>
              { <div dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(recipe.otherInformation) } }/> }
            </Col>
          </Row>
          }

          { recipe.wineRecommendationCategory &&
            <WineRecommendations ingredient={ recipe.wineRecommendationCategory }/>
          }

          { recipe.cookInformations && recipe.cookInformations.length > 0 &&
          <>
            <Row>
              <h4>Tillagningar</h4>
            </Row>
            <Row>
              <Col>
                { renderCookInformation(recipe.cookInformations) }
              </Col>
            </Row>
          </>
          }

          <Row style={ { paddingTop: '20px' } }/>

          <Row>
            <Col>
              <strong>Skapad</strong> <AuditingUserInformation auditInfo={ recipe.created }/>
              <br/>
              <strong>Senast uppdaterad</strong> <AuditingUserInformation auditInfo={ recipe.lastUpdated }/>
            </Col>
          </Row>


        </Container>
      </div>
    );
  }

  function ServingsSelect() {
    const prefix = servingsInfo.prefix;
    const numberOfServings = servingsInfo.numberOfServings;
    const postfix = servingsInfo.postfix;

    let servingsList
    if (numberOfServings === 4) {
      servingsList = [
        numberOfServings * 0.5,
        numberOfServings,
        numberOfServings * 1.5,
        numberOfServings * 2,
        numberOfServings * 3,
      ]
    } else {
      servingsList = [
        numberOfServings * 0.5,
        numberOfServings,
        numberOfServings * 2,
        numberOfServings * 3,
      ]
    }

    let options = [];
    for (const i of servingsList) {
      let text = prefix + i + postfix;
      if (i === numberOfServings) {
        if (selectedNumberOfPortions !== numberOfServings) {
          text = text + " (orginal)"
        }
      }
      options.push(<option value={ i } key={ text }>{ text }</option>)
    }

    return (
      <Form.Control as="select" value={ selectedNumberOfPortions } size="sm" onChange={ handlePortionsChange }>
        { options }
      </Form.Control>
    );
  }

  function handlePortionsChange(event) {
    let value = event.target.value;
    setSelectedNumberOfPortions(parseInt(value));
  }

  function renderImages(images) {
    if (!images || images.length === 0) {
      return undefined;
    }
    return <div style={ { width: "300px" } }>
      <Carousel interval={ 3000 }>
        { images.map((image, index) => {
          return <Carousel.Item key={ index }>{ renderImage(image) }</Carousel.Item>
        }) }
      </Carousel>
    </div>
  }

  function renderImage(image) {
    const baseUrl = 'https://res.cloudinary.com/dkhktoyb0/image/upload';
    let url = image.url;
    let urlPostfix = url.substring(url.lastIndexOf('/upload/') + 8);
    return <Image className="img-fluid" src={ baseUrl + '/c_fill,w_300,r_20/' + urlPostfix }/>

  }

  function IngredientGroups(props) {
    const ingredientGroups = props.ingredientGroups

    const groups = ingredientGroups.map((group, index) =>
      <div key={ index }>
        <IngredientGroup ingredientGroup={ group } selectedPortions={ props.selectedPortions }/>
      </div>
    )
    return (
      <div>
        { groups }
      </div>);
  }

  function IngredientGroup(props) {
    const ingredientGroup = props.ingredientGroup

    let header
    if (ingredientGroup.header) {
      header = <div><b>{ ingredientGroup.header }</b></div>
    }

    return (
      <>
        { header }
        <ul>
          { ingredientGroup.rows.map((row, index) =>
            <li key={ index }>
              <IngredientRow row={ row } selectedPortions={ props.selectedPortions }/>
            </li>
          ) }
        </ul>
      </>);
  }

  function IngredientRow(props) {
    const row = props.row

    const unit = row.unit;
    const ingredient = row.ingredient;

    let calculatedQuantity;
    if (row.quantity && recipe.servings) {
      const quantity = new Decimal(row.quantity);

      const originalPortions = new Decimal(servingsInfo.numberOfServings);
      const selectedPortions = new Decimal(props.selectedPortions);
      calculatedQuantity = quantity.times(selectedPortions.dividedBy(originalPortions)).toString();
    } else {
      calculatedQuantity = row.quantity
    }

    return <>{ calculatedQuantity } { unit } { ingredient }</>
  }

  function StepGroups(props) {
    const stepGroups = props.stepGroups

    const groups = stepGroups.map((group, index) =>
      <div key={ index }>
        <StepGroup stepGroup={ group }/>
      </div>
    )
    return (
      <div>
        { groups }
      </div>);
  }

  function StepGroup(props) {
    const stepGroup = props.stepGroup

    let header
    if (stepGroup.header) {
      header = <div><b>{ stepGroup.header }</b></div>
    }

    return (
      <>
        { header }
        <ul>
          { stepGroup.rows.map((row, index) =>
            <li key={ index }>
              <RecipeTextParser recipe={ recipe } text={ row.step }/>
            </li>
          ) }
        </ul>
      </>);
  }


  function renderSource(source) {
    if (source.url) {
      return <a href={ source.url }>{ source.name }</a>;
    } else {
      return source.name;
    }
  }

  function renderCookInformation(cookInformations) {
    return <div>
      <Table striped bordered hover size="sm">
        <thead>
        <tr>
          <th>Datum</th>
          <th>Användare</th>
          <th>Betyg</th>
          <th>Kommentar</th>
        </tr>
        </thead>
        <tbody>
        { cookInformations.map((cookInformation) => {
          return (
            <tr key={ cookInformation.id }>
              <td>{ cookInformation.date }</td>
              <td>{ cookInformation.by.alias }</td>
              <td><Rating rating={ cookInformation.rating }/></td>
              <td>
                <div dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(cookInformation.comment) } }/>
              </td>
            </tr>
          )
        }) }
        </tbody>
      </Table>
    </div>
  }

  return renderRecipe(recipe)
}
