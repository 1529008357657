import {atom, selector, useRecoilRefresher_UNSTABLE} from "recoil";
import axios from "axios";
import {metadataAtom} from "./metadata";

export const currentUserAtomOld = atom({
  key: 'CurrentUserOld',
  default: selector({
    key: 'CurrentUser/Default',
    get: async () => {
      console.log("currentUserAtom: fooo");
      return await axios.get("/api/user")
        .then(function (response) {
          console.log("CurrentUser", response.data)
          return response.data;
        })
        .catch(function (error) {
          // Not logged in!
          console.log("currentUserAtom: Not logged in!");
          return undefined;
        })
    }
  })
});

export const currentUserAtom = atom({
  key: 'CurrentUser',
  default: null,
});

export const loggedInAtom = atom({
  key: 'LoggedIn',
  default: false,
});