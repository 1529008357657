import React, { useEffect, useState } from 'react';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import queryString from "query-string";
import { getMetaData } from "../redux/actions/metadataActions";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import './SearchPage.css';
import { handleHeaderMessages } from "../utils/headerMessage";
import { RecipeViewDetail } from "../entities/recipe/RecipeViewDetail";
import Fade from 'react-bootstrap/Fade';
import {useRecoilValue} from "recoil";
import {metadataAtom} from "../states/metadata";
import {Row} from "react-bootstrap";

// TODO: params in the url!
const RandomSearchPage = (props) => {
  const metadata = useRecoilValue(metadataAtom);

  const tags = metadata.tags;
  const categories = metadata.categories;

  const [ open, setOpen ] = useState(false);

  const [ selectedTags, setSelectedTags ] = useState([])
  const [ selectedCategories, setSelectedCategories ] = useState([])
  const [ recipe, setRecipe ] = useState([])
  const [ isLoading, setIsLoading ] = useState(true)


  function doSearch() {
    // More correct to do this, but then the page "flickers" for a short while
    // setIsLoading(true)
    setOpen(false)

    let body = {
      "tags": selectedTags,
      "categories": selectedCategories
    }

    let url = queryString.stringifyUrl({ url: '/api/recipes/random', query: {} },
      { skipNull: true, skipEmptyString: true });

    console.log("Searching for:", body)

    axios.post(url, body)
      .then((response) => {
        handleHeaderMessages(response.headers)

        setRecipe(response.data)
        setIsLoading(false)

        // Make the Fade more obvious ;)
        setTimeout(function () {
          setOpen(true)
        }, 300);
      })
      .catch(console.log)
  }


// When tags or categories changes, perform a new search

  useEffect(() => {
    setIsLoading(true)
    doSearch()
  }, [ selectedTags, selectedCategories ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <h3>Slumpgeneratorn</h3>

      { renderSearchForm(categories, tags, handleChange, handleSubmit) }

      <hr/>

      { isLoading
        ? <Spinner animation="border" variant="primary" size="sm"/>
        : <Fade in={ open }>
          <div>
            { renderRecipe(recipe) }
          </div>
        </Fade> }
    </div>
  );

  function handleChange(event) {
    let value = event.target.value;
    let valueName = event.target.name

    // Assume only checkboxes
    let checked = event.target.checked;

    if (valueName === "categories") {
      if (checked) {
        setSelectedCategories(selectedCategories.concat(value))
      } else {
        setSelectedCategories(selectedCategories.filter((i) => i !== value))
      }
    } else {
      if (checked) {
        setSelectedTags(selectedTags.concat(value))
      } else {
        setSelectedTags(selectedTags.filter((i) => i !== value))
      }
    }


  }

  function handleSubmit(event) {
    doSearch()
    event.preventDefault()
  }

  function renderRecipe(recipe) {
    if (!recipe) {
      return <></>
    }
    return (
      <RecipeViewDetail recipe={ recipe }/>
    )
  }


}


function renderSearchForm(categories, tags, onChangeCallback, onSubmitCallback) {
  return (
    <div>
      <Form id={ "searchForm" } onSubmit={ onSubmitCallback }>

        <Row>
          <Col sm={ 2 }>
            <b>Kategori</b>
          </Col>
          <Col sm={ 4 }>
            { categories.map((category) => (
              <Form.Check inline key={ category } type="checkbox" name="categories" value={ category } label={ category } onChange={ onChangeCallback }/>
            )) }
          </Col>

          <Col sm={ 1 }>
            <b>Tag</b>
          </Col>
          <Col sm={ 4 }>
            { tags.map((tag) => (
              <Form.Check inline key={ tag } type="checkbox" label={ tag } value={ tag } name="tags" onChange={ onChangeCallback }/>
            )) }
          </Col>
        </Row>

        <Row>
          <Col sm={ { span: 10, offset: 2 } }>
            <Button type="submit">Hitta ett recept</Button>
          </Col>
        </Row>
      </Form>

    </div>
  );
}

export default RandomSearchPage;