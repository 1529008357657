import { toast } from "react-toastify";

export function handleHeaderMessages(headers) {
  let headerMessages = headers['x-atdev-messages'];
  if (headerMessages) {
    let messageList = JSON.parse(headerMessages)
    for (const headerMessage of messageList) {
      if (headerMessage.type === "SUCCESS") {
        toast.success(headerMessage.message)
      } else if (headerMessage.type === "ERROR") {
        toast.error(headerMessage.message)
      } else {
        toast.info(headerMessage.message)
      }
    }
  }
}