import React, {useEffect, useState} from 'react';
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Tags from "../components/Tags";
import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";
import Container from "react-bootstrap/Container";
import './HomePage.css';
import Badge from "react-bootstrap/Badge";
import App, {anonymousAxios} from "../App";
import {Alert, Button} from "react-bootstrap";
import CreateFamilyDialog from "../dialogs/CreateFamilyDialog";
import JoinFamilyDialog from "../dialogs/JoinFamilyDialog";
import Jumbotron from "../components/Jumbotron";
import {useRecoilValue} from "recoil";
import {currentUserAtom} from "../states/auth";
import axios from "axios";
//import axios from "../App"

const HomePage = () => {
  const [recipes, setRecipes] = useState([])
  const currentUser = useRecoilValue(currentUserAtom);

  
  const [ quote, setQuote ] = useState(null)
  const [ test, setTest ] = useState(null)
  const [ wine, setWine ] = useState(null)
  const [ showCreateFamilyDialog, setShowCreateFamilyDialog ] = useState(false)
  const [ showJoinFamilyDialog, setShowJoinFamilyDialog ] = useState(false)

  useEffect(() => {
    // TODO:
    //anonymousAxios.get('https://backend.omenstund.se/getwinernd/sv').then((response) => {
    //  console.log("WINE response", response.data)
    //  setWine(response.data)
    //});
    setWine(undefined)
  }, [ ]);

  useEffect(() => {
    axios.get('/api/recipes/forHomePage').then((response) => {
      setRecipes(response.data)
    });
  }, [ ]);

  useEffect(() => {
    axios.get('/test/check-authentication').then((response) => {
      setTest(response.data)
    });
  }, [ currentUser]);


  useEffect(() => {
    anonymousAxios.get('https://quotes.atdev.se/quotes/').then((response) => {
      console.log("Quotes response", response)
      setQuote(response.data.quote)
    });
  }, [ ]);

  let recipesFragment;
  if (recipes.length === 0) {
    recipesFragment = <Spinner animation="border" variant="primary"/>
  } else {
    insertWineInRandomPlace(recipes)
    recipesFragment =
      /*TODO: was CardDeck */
      <div>
        { renderCardDeck(recipes, wine) }
      </div>
  }

  let dialogFragments = null;
  if (showCreateFamilyDialog) {
    dialogFragments = <CreateFamilyDialog showModal={showCreateFamilyDialog} setShowModal={setShowCreateFamilyDialog} />
  }
  if (showJoinFamilyDialog) {
    dialogFragments = <JoinFamilyDialog familyId={currentUser.family.id} familyName={currentUser.family.name}
                                        showModal={showJoinFamilyDialog} setShowModal={setShowJoinFamilyDialog} />
  }

  let showFamilyAlert = false;
  if (currentUser) {
    if(!currentUser.family) {
      showFamilyAlert = true;
    } else {
      if (!currentUser.family.isMember && currentUser.family.isInvited) {
        showFamilyAlert = true;
      }
    }
  }

  // showFamilyAlert = currentUser && ((!currentUser.family) || (currentUser.family && currentUser.family.isInvited && !currentUser.family.isMember))

  const [showJoinFamily, setJoinFamilyShow] = useState(showFamilyAlert);
  let joinFamilyFragment;
  if (showJoinFamily) {
    joinFamilyFragment = (
      <Alert variant="danger" onClose={() => setJoinFamilyShow(false)} dismissible>
        <Alert.Heading>Hey! Du saknar familj!</Alert.Heading>
        <p>
          För att få maximal användning av denna förträffliga webbsida så måste du vara medlem i en familj!
        </p>
        <hr />
        <p className="mb-0">
          { currentUser.family && currentUser.family.isInvited &&
              <>
                Du har fått en inbjudan! <Button variant="primary" onClick={ () => { setShowJoinFamilyDialog(true)  }}>Gå med</Button> i Familjen!
              </>
            }
          { !currentUser.family &&
              <>
            <Button variant="primary" onClick={ () => { setShowCreateFamilyDialog(true) }}>Skapa</Button> en ny Familj!
              </>
          }
        </p>
      </Alert>
    );
  }

  return (
    <div>
      { test &&
        <p>{ test }</p>
      }

      { dialogFragments }
      { joinFamilyFragment }
      <Jumbotron>
        { currentUser &&
        <h3>Välkommen till Familjerecept <b>{ currentUser.username }</b>!</h3>
        }
        { !currentUser &&
        <h3>Välkommen till Familjerecept!</h3>
        }
        <div>
          <p>Här kan du hitta lite olika favoritrecept som har sparats genom åren! <span role={ "img" } aria-label="glad gubbe">😊</span></p>
          Sidan drivs av Lennart som ett hobbyprojekt - läs mer om det och hur den är uppbyggd <Nav as={ Link } to={ '/about' } className="customLink">här</Nav>.
        </div>
        { quote &&
        <div><br/><b>Dagens julcitat:</b> <span style={{fontStyle: 'italic'}}>{ quote }</span></div>
        }
      </Jumbotron>

      { recipesFragment }

    </div>
  );
}

function renderCardDeck(recipes, wine) {
  return (
    <Container>
      {/* TODO: was CardDeck*/}
      <div>{ recipes.map((recipe, index) => {
        let recipeOutput;
        if (recipe === "--wine--") {
          recipeOutput = renderWine(wine);
        } else {
          recipeOutput = renderRecipe(recipe);
        }

        // Based on https://stackoverflow.com/questions/36487389/bootstrap-4-card-deck-table-cell-columns-responsive-based-on-viewport/36499275#36499275
        // and https://www.codeply.com/go/k2zBsujRhJ
        let wrappingDiv = [];
        // console.log("index: " + index + ", %2=" + (index % 2) + ", %3=" + (index % 3) + ", %4=" + (index % 4));

        if (index > 0) {
          if (index % 2 === 1) {
            // wrap every 2 on sm
            // console.log("> wrap every 2 on sm");
            wrappingDiv.push(<div key={ index + '-2sm' } className="w-100 d-none d-sm-block d-md-none"/>)
          }
          if (index % 3 === 2) {
            // wrap every 3 on md & lg
            // console.log("> wrap every 3 on md");
            wrappingDiv.push(<div key={ index + '-3md' } className="w-100 d-none d-md-block d-lg-block d-xl-none"/>)
          }
          if (index % 4 === 3) {
            // wrap every 4 on xl
            wrappingDiv.push(<div key={ index + '-4xl' } className="w-100 d-none d-xl-block"/>)
          }
        }

        return <React.Fragment key={ 'fragment-' + recipe.id }>
          { recipeOutput }
          { wrappingDiv }
        </React.Fragment>;
      })
      }</div>
    </Container>)
}

function renderRecipe(recipe) {
  return (
    <Card key={ recipe.id } className={ 'mb-4' }>
      { recipe.isNew &&
      <div className="badge-ribbon" style={ { "--d": "4px", "--w": "100px", "--c": "green" } }>Ny!</div>
      }

      { renderImage(recipe) }
      <Card.Body>
        <Card.Header style={ { textAlign: 'left' } }>
          <Nav as={ Link } to={ '/recipe/' + recipe.uriPath }>{ recipe.name }</Nav></Card.Header>
        <Card.Text style={ { textAlign: 'left' } }>
          { recipe.isUpdated &&
          <Badge variant="danger">Uppdaterad!</Badge>
          }
          { recipe.description }
        </Card.Text>
        {/*<Button variant="primary">Go somewhere</Button>*/ }
        {/*<footer className="blockquote-footer">*/ }
        {/*  Skapades av <cite title="Source Title">{ recipe.createdBy.alias }</cite>*/ }
        {/*</footer>*/ }
      </Card.Body>
      <Card.Footer style={ { textAlign: 'center' } }>
        <small className="text-muted"><Tags tags={ recipe.tags }/></small>
      </Card.Footer>
    </Card>
  );
}

function renderImage(recipe) {
  let img;
  if (recipe.images && recipe.images.length > 0) {
    img = <Nav.Link to={ '/recipe/' + recipe.uriPath } as={ Link }>
      <Card.Img variant="top" src={ recipe.images[0].url }/>
    </Nav.Link>;
  }
  return img;
}

function insertWineInRandomPlace(recipes) {
  let recipesCopy = [...recipes]
  let winePos = randomIntFromInterval(0,10) // 11 positions...

  // Ugly.. but it seems that we need to ensure that we only have one wine...
  recipes.length = 0
  recipesCopy.forEach(function (recipe, i) {
    if (i === winePos) {
      recipes.push("--wine--")
    }
    // Seems that the old wine remains on the page when revisiting... due to global state
    if (recipe !== "--wine--") {
      recipes.push(recipe)
    }
  });
}

// min and max included
export function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function renderWine(wine) {
  return (
    <Card key={ "wine" } className={ 'mb-4' }>
      <div className="badge-ribbon" style={ { "--d": "4px", "--w": "100px", "--c": "#ff008c" } }>Vin</div>

      { wine &&
        <Card.Body>
          <Card.Header style={ { textAlign: 'left' } }>
            <a href={ "https://vin.omenstund.se/wineDisplay?wineId=" + wine.wineId } target="_blank" rel="noreferrer">{ wine.name }</a>
          </Card.Header>
          <Card.Text style={ { textAlign: 'left' } }>
            { wine.description }
            ({ wine.color })
          </Card.Text>
          {/*<Button variant="primary">Go somewhere</Button>*/ }
          {/*<footer className="blockquote-footer">*/ }
          {/*  Skapades av <cite title="Source Title">{ recipe.createdBy.alias }</cite>*/ }
          {/*</footer>*/ }
        </Card.Body>
      }
      <Card.Footer style={ { textAlign: 'center' } }>
        <small className="text-muted">Från <a href="https://vin.omenstund.se/" target="_blank" rel="noreferrer">VinGrottan</a>!</small>
      </Card.Footer>
    </Card>
  );
}

export default HomePage;