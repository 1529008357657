import React from 'react'
import {Route, Routes, useLocation} from "react-router-dom";
import AboutPage from "../pages/AboutPage";
import Footer from "../components/Footer";
import SideNavbar from "../components/SideNavbar";
import Header from "../components/Header";
import HomePage from "../pages/HomePage";
import CloudPage from "../pages/CloudPage";
import SearchPage from "../pages/SearchPage";
import RandomSearchPage from "../pages/RandomSearchPage";
import RecipeRoutes from "../entities/recipe/RecipeRoutes";
import RecipeViewPage from "../entities/recipe/RecipeViewPage";
import EventRecent from "../entities/event/EventRecent";
import PageNotFound from "../pages/PageNotFound";
import RecipeCreateDetails from "../entities/recipe/RecipeCreateDetail";
import TestPage from "../pages/TestPage";
import RecipeEditDetails from "../entities/recipe/RecipeEditDetail";
import RecipeImageManager from "../entities/recipe/RecipeImageManager";
import RecipeCookInformationManager from "../entities/recipe/RecipeCookInformationManager";
import FamilyPage from "../pages/FamilyPage";

const ROUTES = {
  ABOUT: "/about",
  FAMILY: "/family",
  RANDOM_SEARCH: "/search/random",
  SEARCH: "/search",
  CLOUD_TAGS: "/cloud/tags",
  HOME: "/",
  ENTITY: "/",
};

function AppRouter() {
  const searchQuery = useLocation().search;

  const loggedInContent =
    <>
      <Routes>
        <Route path={ROUTES.ABOUT} element={<AboutPage/>}/>
        <Route path={ROUTES.FAMILY} element={<FamilyPage/>}/>
        <Route path={ROUTES.RANDOM_SEARCH} element={<RandomSearchPage key={searchQuery}/>}/>
        <Route path={ROUTES.SEARCH} element={<SearchPage key={searchQuery}/>}/>
        <Route path={ROUTES.CLOUD_TAGS} element={<CloudPage type="tags"/>}/>
        <Route path={ROUTES.HOME} element={<HomePage/>}/>
        <Route path={"/recipe"}>
          <Route exact path={`/recipe/new`} key="new" element={<RecipeCreateDetails />}/>
          <Route exact path={`/recipe/:recipeId/edit`} key="edit" element={<RecipeEditDetails />}/>
          <Route exact path={`/recipe/:recipeId/edit/images`} key="edit" element={<RecipeImageManager/>}/>
          <Route exact path={`/recipe/:recipeId/cookInformation/add`} key="edit" element={<RecipeCookInformationManager/>}/>
          {/* Special key here: otherwise the page did not re-render when using the recipe search from this page... */}
          <Route path="">
            <Route path=":uriPath" element={<RecipeViewPage/>}/>
            <Route path="" element={<RecipeViewPage/>}/>
          </Route>
        </Route>
        <Route path={"/events"}>
          <Route path={"recent"} key="recentEvents" element={<EventRecent />}/>
          <Route path="*" element={<p>Events path not resolved</p>}/>
        </Route>
        <Route path="/test" element={<TestPage/>}/>
        <Route path="*" element={<PageNotFound />}/>
      </Routes>
    </>

  return (
    <div className="App">
      <div className="wrapper">
        <SideNavbar/>
        <div id="content">
          <Header/>
          <div id="page">
            {loggedInContent}
          </div>
          <Footer/>
        </div>
      </div>
    </div>
  );
}

export default AppRouter