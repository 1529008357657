import React, { useEffect, useState } from "react";
import axios from 'axios';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { AuditingUserInformation } from "../../components/AuditingUserInformation";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";

const EventRecent = () => {
  console.log("EventRecent")
  const [ events, setEvents ] = useState([])

  useEffect(() => {
    axios.get('/api/events').then((response) => {
      setEvents(response.data)
    });
  }, []);

  return (
    <Container>
      <h2>Händelser</h2>
      {/* TODO: was CardColumns */}
      <div>
        { events && events.map((event, index) => {
          return renderEvent(event)
        }) }
        { events && events.length === 0 &&
          <>Inget har hänt nyligen :(</>
        }
      </div>
    </Container>
  )
}

function renderEvent(event) {
  return (
    <Card className="p-3" key={ event.id }>
      <Card.Body>
        <Card.Title>{ getTitle(event) }</Card.Title>
        <Card.Text>
          { getEventDescription(event) }
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <small className="text-muted"><AuditingUserInformation auditInfo={ event.created }/></small>
      </Card.Footer>
    </Card>
  )
}

function getTitle(event) {
  if (event.eventType === "RECIPE_CREATED") {
    return <i className="fas fa-plus"/>
  } else if (event.eventType === "RECIPE_UPDATED") {
    return <i className="fas fa-edit"/>
  } else if (event.eventType === "USER_UPLOADED_IMAGE") {
    return <i className="fas fa-image"/>
  } else {
    return <i className="fas fa-exclamation-circle"/>
  }

}

function getEventDescription(event) {
  let recipeLink = <Nav as={ Link } to={ '/recipe/' + event.recipe.uriPath } style={ { display: 'inline' } }>{ event.recipe.name }</Nav>;
  if (event.eventType === "RECIPE_CREATED") {
    return <div>Receptet { recipeLink } skapades</div>;
  } else if (event.eventType === "RECIPE_UPDATED") {
    return <span>Receptet { recipeLink } uppdaterades</span>;
  } else if (event.eventType === "USER_UPLOADED_IMAGE") {
    return <span>Receptet { recipeLink } skapades</span>;
  } else {
    return <span>Okänt event ({ event.eventType } </span>
  }
}

export default EventRecent;