import Modal from "react-bootstrap/Modal";
import * as yup from "yup";
import {Button, Form} from "react-bootstrap";
import {Formik} from "formik";
import axios from "axios";
import {handleHeaderMessages} from "../utils/headerMessage";

const schema = yup.object().shape({
  familyName: yup.string()
      .min(2, "minst 2 tecken krävs")
      .required("Familjenamn krävs")
});

function CreateFamilyDialog({showModal, setShowModal}) {
  const handleClose = () => setShowModal(false);

  const handleSubmit = (event) => {
    let familyName = event.familyName;
    let body = {
      "name": familyName
    }
    console.log("POST create family:", body)
    axios.post("api/families", body)
        .then((response) => {
          console.log("response", response)

          handleHeaderMessages(response.headers)

          // Set window location
          window.location.href = "/family";
        });
  };

  return (
      <>
        <Modal show={showModal} onHide={handleClose}>
          <Formik
              validationSchema={schema}
              onSubmit={handleSubmit}
              initialValues={{
                familyName: ''
              }}
          >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>

                  <Modal.Header closeButton>
                    <Modal.Title>Skapa familj</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                    <Form.Group controlId="validationFormik03">
                      <Form.Label>Namn</Form.Label>
                      <Form.Control
                          type="text"
                          placeholder=""
                          name="familyName"
                          value={values.familyName}
                          onChange={handleChange}
                          isInvalid={!!errors.familyName}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.familyName}
                      </Form.Control.Feedback>
                    </Form.Group>

                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Avbryt
                    </Button>
                    <Button type="submit">
                      Skapa familj
                    </Button>
                  </Modal.Footer>
                </Form>
            )}
          </Formik>
        </Modal>
      </>
  );
}

export default CreateFamilyDialog;

