import React from 'react';
import Nav from "react-bootstrap/Nav";
import RecipeAutoSuggest from "./RecipeAutoSuggest";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import {useRecoilValue} from "recoil";
import {keycloak} from "../App";
import {currentUserAtom, loggedInAtom} from "../states/auth";

const registrationUrl = window.ENV.KEYCLOAK_HOST + "/realms/" + window.ENV.KEYCLOAK_REALM + "/protocol/openid-connect/registrations?client_id=familjerecept-frontend&response_type=code&scope=openid email&redirect_uri=https://familjerecept.nu"
const profileUrl = window.ENV.KEYCLOAK_HOST + "/realms/" + window.ENV.KEYCLOAK_REALM + "/account?referrer=familjerecept-frontend#/personal-info"

const Header = () => {
  const isLoggedIn = useRecoilValue(loggedInAtom);
  const currentUser = useRecoilValue(currentUserAtom);

  let userMenu;
  if (isLoggedIn) {
    userMenu =
      <NavDropdown key={"loggedIn"} title={ <>{ currentUser.username }&nbsp;<i className="fa fa-user" aria-hidden="true"/></> } id="basic-nav-dropdown" drop="down"
                   style={ { zIndex: '2000' } }>
        <NavDropdown.Item href={ profileUrl }>Användarinställningar</NavDropdown.Item>
        <NavDropdown.Divider/>
        <NavDropdown.Item onClick={ () => keycloak.logout() }>Logout</NavDropdown.Item>
      </NavDropdown>
  } else {
    userMenu =
      <NavDropdown key={"anonymous"} title={ <i className="fa fa-user" aria-hidden="true"/> } id="basic-nav-dropdown" drop="down">
        <NavDropdown.Item href={ registrationUrl }>Skapa konto</NavDropdown.Item>
        <NavDropdown.Item onClick={ () => keycloak.login() }>Logga in</NavDropdown.Item>
      </NavDropdown>
  }

  return (
    <header>

      <Navbar bg="light" expand="lg">
        <button type="button" id="sidebarCollapse" className="navbar-btn">
          <span/>
          <span/>
          <span/>
        </button>

        <RecipeAutoSuggest className="mr-sm-4"/>

        <Nav className="mr-auto"/>
        { userMenu }
      </Navbar>
    </header>
  );
}

export default Header