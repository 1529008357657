import React, { useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import './Timer.css'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import useSound from 'use-sound';
import alarmSfx from '../sounds/alarm.mp3';
import risingPopsSfx from '../sounds/rising-pops.mp3';
import { toast } from "react-toastify";
import Jumbotron from "./Jumbotron";

export const Timer = (props) => {
  const [ key, setKey ] = useState(1);
  const [ playAlarm ] = useSound(alarmSfx);
  const [ playRisingPops ] = useSound(risingPopsSfx);
  const [ isPlaying, setIsPlaying ] = useState(false);

  let globalRemainingTime = -1;

  const time = props.time
  const show = props.show

  let groups = time.match(/(\d*h)?(\d*m)?/)
  let originalHours = 0
  let originalMinutes

  originalHours = parseInt(groups[1]) || 0
  originalMinutes = parseInt(groups[2]) || 0

  if (originalMinutes > 60) {
    originalHours += Math.floor(originalMinutes / 60)
    originalMinutes = originalMinutes % 60
  }

  let timeInSeconds = ((originalHours * 60) + originalMinutes) * 60

  if (!show) {
    return <></>
  }

  // Small
  let timerProps = {
    isPlaying: true,
    size: 100,
    strokeWidth: 6
  };

  if (originalHours > 0) {
    // Bigger
    timerProps = {
      isPlaying: true,
      size: 140,
      strokeWidth: 6
    };
  }

  // Reset functionality inspired from https://codesandbox.io/s/tender-bogdan-qd35m?file=/src/index.js:756-765

  function resetTimer() {
    setKey(prevKey => prevKey + 1)
  }

  return (
    <Container fluid="md">
      <Row>
        <Col>
          <Jumbotron>
            <CountdownCircleTimer
              { ...timerProps }
              key={ key }
              isPlaying={ isPlaying }
              duration={ timeInSeconds }
              onComplete={() => {
                playAlarm()
                return [false, 0] // don't repeat
              }}
              colors={ [
                [ '#004777', 0.33 ],
                [ '#F7B801', 0.33 ],
                [ '#A30000', 0.33 ],
              ] }
            >
              { ({ remainingTime }) => renderTime(remainingTime) }
            </CountdownCircleTimer>
          </Jumbotron>
        </Col>
        <Col>
          <ButtonGroup vertical>
            <Button onClick={ () => {
              if (globalRemainingTime === 0) {
                resetTimer()
              }
              playRisingPops()
              toast.info("Timer startad. Om du inte hörde något ljud så var vänlig verifiera att din enhet inte är i tyst läge")
              setIsPlaying(true)
            } } style={ { marginTop: "4px" } }>Start</Button>
            <Button onClick={ () => {
              setIsPlaying(false)
            } } style={ { marginTop: "4px" } }>Pause</Button>
            <Button onClick={ () => resetTimer() } style={ { marginTop: "4px" } }>Reset</Button>
          </ButtonGroup>
        </Col>
      </Row>
    </Container>

  )

  function renderTime(time) {
    globalRemainingTime = time
    return (
      <div className="time-wrapper">
        <div className="time">{ formatRemainingTime(time) }</div>
      </div>
    );
  }

  function formatRemainingTime(remainingTime) {
    const hours = Math.floor(remainingTime / 3600)
    const minutes = Math.floor((remainingTime % 3600) / 60)
    const seconds = remainingTime % 60

    let formattedHours = ("0" + hours).slice(-2);
    let formattedMinutes = ("0" + minutes).slice(-2);
    let formattedSeconds = ("0" + seconds).slice(-2);

    if (originalHours > 0) {
      return `${ formattedHours }:${ formattedMinutes }:${ formattedSeconds }`
    } else {
      return `${ formattedMinutes }:${ formattedSeconds }`
    }
  }
}

