import React from 'react'
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import '../../pages/recipe-tag.css'
import RecipeForm from "./RecipeForm";
import {useRecoilRefresher_UNSTABLE, useRecoilValue} from "recoil";
import {loggedInAtom} from "../../states/auth";
import {handleHeaderMessages} from "../../utils/headerMessage";
import axios from "axios";
import {metadataAtom} from "../../states/metadata";

// TODO: extract Tag to own component?
const RecipeCreateDetails = () => {
  const metaDataRefresher = useRecoilRefresher_UNSTABLE(metadataAtom);
  const navigate = useNavigate()

  const loggedIn = useRecoilValue(loggedInAtom);

  // TODO: make this more generic (for example A LoggedInPage
  if (!loggedIn) {
    navigate("/")
    toast.error("Måste vara inloggad för att uppdatera recept")
  }

  // Default recipe!
  const recipe = {
    name: "",
    ingredients: [],
    steps: [],
    tags: []
  }

  return (
    <RecipeForm recipe={recipe}
                onSubmitFunction={onSubmitCreateRecipe}
                metaDataRefresher={metaDataRefresher}/>
  )
}

export const onSubmitCreateRecipe = (navigate, oldRecipe, values, actions, inputTags, metaDataRefresher) => {
  console.log("CreateRecipe", values, inputTags)
  // Map UI tag format to BE format...
  const tags = inputTags.map((tag) => {
    return tag.value
  })

  const ingredientsAsString = values.ingredientsString
  const stepsAsString = values.stepsString

  let body = {
    name: values.name,
    category: values.category,
    wineRecommendationCategory: values.wineRecommendationCategory,
    tags: tags,
    description: values.description,
    cookingTime: values.cookingTime,
    servings: values.servings,
    ingredientsAsString: ingredientsAsString,
    stepsAsString: stepsAsString,
    source: {
      name: values.sourceName,
      url: values.sourceUrl
    },
    otherInformation: values.otherInformation
  }

  // createRecipe(history, oldRecipe, values, actions, body)
  let url = '/api/recipes'
  console.log("Going to post to url " + url + ":", JSON.stringify(body, null, 2))

  axios.post(url, body).then((response) => {
    console.log("Result: " + (JSON.stringify(response.data, null, 2)))
    actions.setSubmitting(false)
    navigate("/recipe/" + response.data.uriPath)
    handleHeaderMessages(response.headers)
    metaDataRefresher()
  }).catch(function (error) {
    console.log("Error:", error)
    toast.error("Fel uppstod: " + error)
  })

}

export default RecipeCreateDetails