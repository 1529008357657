import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {AuditingUserInformation} from "../../components/AuditingUserInformation";
import NavLink from "react-bootstrap/NavLink";
import {useDrag, useDrop} from "react-dnd";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import update from 'immutability-helper'
import bsCustomFileInput from 'bs-custom-file-input';
import RecipeImageUpload from "./RecipeImageUpload";
import Jumbotron from "../../components/Jumbotron";
import {useNavigate} from "react-router";
import axios from "axios";
import {useRecoilValue} from "recoil";
import {loggedInAtom} from "../../states/auth";

const RecipeImageManager = () => {
  let navigate = useNavigate()

  let {recipeId} = useParams();

  const [recipe, setRecipe] = useState(null)
  const isLoggedIn = useRecoilValue(loggedInAtom);

  const [recipeImages, setRecipeImages] = useState([])

  console.log("recipeId: ", recipeId)
  useEffect(() => {
    refreshRecipe();
  }, [recipeId]);

  function refreshRecipe() {
    axios.get("/api/recipes/" + recipeId)
      .then(function (response) {
        console.log("Get recipe success:", response.data);
        setRecipe(response.data)
        setRecipeImages(response.data.images)
      });
  }

  useEffect(() => {
    bsCustomFileInput.init()
  }, []);

  // TODO: make this more generic (for example A LoggedInPage
  if (!isLoggedIn) {
    navigate("/")
    toast.error("Måste vara inloggad för att uppdatera recept")
  }

  function updateImageOrders() {
    let idsInOrder = recipeImages.map(value => value.id);
    let url = `/api/recipes/${recipeId}/images/order`;

    axios.post(url, idsInOrder).then(res => {
      toast.success("Bilderna har sorterats om")
      refreshRecipe();
    }).catch(err => {
      toast.error("Misslyckades med att sortera om bilderna: " + err)
    })
  }

  const moveRecipeImage = (url, atIndex) => {
    const {recipeImage, index} = findRecipeImage(url)
    let update1 = update(recipeImages, {
      $splice: [
        [index, 1],
        [atIndex, 0, recipeImage],
      ],
    });
    setRecipeImages(
      update1,
    )
  }

  const findRecipeImage = (url) => {
    const imageList = recipeImages.filter((c) => c.url === url)
    const image = imageList[0]
    let index = recipeImages.indexOf(image);
    return {
      recipeImage: image,
      index: index,
    }
  }

  const deleteRecipeImageHandle = (id) => {
    console.log("WIll delete image with url", id)
    let url = `/api/recipes/${recipeId}/images/${id}`;
    console.log("Deleting image", url)

    axios.delete(url)
      .then((response) => {
        toast.success("Bilden togs bort")
        // noinspection JSCheckFunctionSignatures
        refreshRecipe();
      })
      .catch((error) => {
        toast.error("Fel uppstod när receptes försökte tas bort: " + error)
      });
  }

  const [, drop] = useDrop({accept: ItemTypes.IMAGE})

  if (!recipe) {
    return <Spinner animation="border" variant="primary"/>
  }

  return (
    <div className={'recipeEditImages'} style={{textAlign: 'left'}}>
      <Container fluid>

        <Row><h2>Bildadministrering</h2></Row>
        <Row><NavLink to={"/recipe/" + recipe.uriPath} as={Link}><i className="fa fa-backward"
                                                                    aria-hidden="true"/>&nbsp; Tillbaka till
          receptet</NavLink></Row>

        <Row style={{paddingTop: '20px'}}/>
        <Row>
          <table>
            <tbody>
            <tr>
              <th scope="row" style={{width: '130px'}}>Recept</th>
              <td>{recipe.name}</td>
            </tr>
            </tbody>
          </table>
        </Row>
        <Row style={{paddingTop: '40px'}}/>

        <Row><h4>Bilder</h4></Row>

        <div ref={drop} style={style}>
          {recipeImages.map((image, index) => (
            <RecipeImage
              key={index}
              image={image}
              moveRecipeImage={moveRecipeImage}
              updateImageOrders={updateImageOrders}
              findRecipeImage={findRecipeImage}
              deleteRecipeImage={deleteRecipeImageHandle}
            />
          ))}
        </div>


        <Jumbotron style={{padding: "10px", marginTop: '20px'}}>
          <RecipeImageUpload
            recipeId={recipe.id}
            refreshRecipe={refreshRecipe}
          />
        </Jumbotron>

      </Container>
    </div>
  )
}

function getImageUrl(image) {
  const baseUrl = 'https://res.cloudinary.com/dkhktoyb0/image/upload';
  let url = image.url;
  let urlPostfix = url.substring(url.lastIndexOf('/upload/') + 8);
  // TODO: to make this more flexible - prepare backend to return better data!
  return baseUrl + '/c_thumb,w_200,g_face/' + urlPostfix
}

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
}
const RecipeImage = ({image, moveRecipeImage, updateImageOrders, findRecipeImage, deleteRecipeImage}) => {
  console.log("RecipeImage image", image)
  const url = image.url

  const originalIndex = findRecipeImage(url).index
  const [{isDragging}, drag] = useDrag({
    item: () => ({id: url, originalIndex}),
    type: ItemTypes.IMAGE,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const {id: droppedId, originalIndex} = monitor.getItem()
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        moveRecipeImage(droppedId, originalIndex)
      } else {
        // Trigger save!
        updateImageOrders()
      }
    },
  })
  const [, drop] = useDrop({
    accept: ItemTypes.IMAGE,
    canDrop: () => false,
    hover({id: draggedId}) {
      if (draggedId !== url) {
        const {index: overIndex} = findRecipeImage(url)
        moveRecipeImage(draggedId, overIndex)
      }
    },
  })
  const opacity = isDragging ? 0 : 1
  return (
    <div ref={(node) => drag(drop(node))} style={{...style, opacity}}>
      <Card style={{width: '20rem', padding: '5px'}}>
        <Card.Img variant="top" src={getImageUrl(image)} style={{width: "200px"}}/>
        <Card.Body>
          {/*<Card.Title>{ image.id }</Card.Title>*/}
          {/*<Card.Text>*/}
          {/*  Label...*/}
          {/*</Card.Text>*/}
          <Card.Text>
            <small className="text-muted">Laddades upp <AuditingUserInformation auditInfo={image.created}/></small>
          </Card.Text>
          <Button variant="primary" onClick={() => deleteRecipeImage(image.id)}><i className="fa fa-trash"
                                                                                   aria-hidden="true"/> Ta bort</Button>
        </Card.Body>
      </Card>
    </div>
  )
}

export const ItemTypes = {
  IMAGE: 'image',
}


export default RecipeImageManager
