
// A custom hook that builds on useLocation to parse
// the query string for you.
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const withRouter = (Component) =>{
  const Wrapper = (props) =>{
    const history = useNavigate();
    return <Component history={history} {...props}/>
  }
  return Wrapper;
}