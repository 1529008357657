// Heavily inspired by https://codepen.io/moroshko/pen/GEPoQW

import React, {Component, useState} from 'react';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'
import Spinner from "react-bootstrap/Spinner";
import axios from 'axios';
import {withRouter} from "../utils/hooks";
import {useNavigate} from "react-router-dom";

// Called when it is selected (using up/down for example)
function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSuggestion(suggestion, {query}) {
  let textToDisplay = suggestion.name + " (" + suggestion.category + ")";
  const matches = AutosuggestHighlightMatch(textToDisplay, query);
  const parts = AutosuggestHighlightParse(textToDisplay, matches);

  return (
    <span>
      {parts.map((part, index) => {
        const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;

        return (
          <span className={className} key={index}>
            {part.text}
          </span>
        );
      })}
    </span>
  );
}


const RecipeAutoSuggest = () => {
  const navigate = useNavigate();

  const [lastRequestId, setLastRequestId] = useState(null);
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function loadSuggestions(value) {
    // Cancel the previous request
    if (lastRequestId !== null) {
      clearTimeout(lastRequestId);
    }

    setIsLoading(true);

    // Fake request
    let query = {
      text: value
    };
    let url = '/api/recipes/autocomplete';

    axios.get(url, {params: query})
      .then((response) => {
        let recipes = response.data;
        setSuggestions(recipes);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const onChange = (event, {newValue}) => {
    setValue(newValue);
  };

  const  onSuggestionsFetchRequested = ({value}) => {
    loadSuggestions(value);
  };

  const  onSuggestionsClearRequested = () => {
    setSuggestions([])
  };

  // onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
  const  onSuggestionSelected = (event, {suggestion}) => {
    if (suggestion != null) {
      let recipe = suggestion;
      if (recipe.uriPath) {
        let url = '/recipe/' + recipe.uriPath;
        navigate(url);
        // Reset after initiating new page load
        setValue('');
        setSuggestions([]);
        setIsLoading(false);
      }
    }
  }

  const inputProps = {
    placeholder: "Snabbsök recept",
    value,
    onChange: onChange
  };
  const spinner = (isLoading ? <Spinner animation="border" variant="primary" size="sm"/> : '');

  return (
    <div>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps}/>
      {spinner}
    </div>
  );

}


export default RecipeAutoSuggest;