import {Link, useNavigate, useParams} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import NavLink from "react-bootstrap/NavLink";
import * as yup from "yup";
import { Field, Formik } from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sv from 'date-fns/locale/sv'
import ReactStars from "react-rating-stars-component";
import { Editor } from "@tinymce/tinymce-react";
import Button from "react-bootstrap/Button";
import Jumbotron from "../../components/Jumbotron";
import {useRecoilRefresher_UNSTABLE, useRecoilValue} from "recoil";
import {metadataAtom} from "../../states/metadata";
import {loggedInAtom} from "../../states/auth";
import axios from "axios";
import {handleHeaderMessages} from "../../utils/headerMessage";

const schema = yup.object({
  date: yup.date()
    .required("Datum krävs"),

  rating: yup.number(),

  comment: yup.string()
})

const RecipeCookInformationManager = (props) => {
  const navigate = useNavigate()

  const {recipeId} = useParams();
  const metaDataRefresher = useRecoilRefresher_UNSTABLE(metadataAtom);
  const isLoggedIn = useRecoilValue(loggedInAtom);
  const [recipe, setRecipe] = useState(null)

  console.log("recipeId: ", recipeId)
  useEffect(() => {
    axios.get("/api/recipes/" + recipeId)
      .then(function (response) {
        console.log("Get recipe success:", response.data);
        setRecipe(response.data)
      });
  }, [recipeId]);
  const [ date, setDate ] = useState(new Date());

  // Need to convert ingredients (list) to a string ...
  let initialValues = {
    date: new Date().toISOString().split('T')[0],
  }

  // TODO: make this more generic (for example A LoggedInPage
  if (!isLoggedIn) {
    navigate("/")
    toast.error("Måste vara inloggad för att uppdatera recept")
  }

  if (!recipe) {
    return <Spinner animation="border" variant="primary"/>
  }

  function onSubmitFunction(navigate, recipe, values, actions) {
    console.log("date", values.date)
    console.log("rating", values.rating)
    console.log("comment", values.comment)

    let url = '/api/recipes/' + recipeId + "/cookInformation";
    let body = {
      date: values.date,
      rating: values.rating,
      comment: values.comment
    }

    axios.post(url, body).then(response => {
      handleHeaderMessages(response.headers)
      //store.dispatch(getRecipesDetails(recipeId))
      navigate("/recipe/" + recipe.uriPath)
    }).catch(err => {
    }).catch(err => {
      toast.error("Misslyckades med att lägga till en tillagning': " + err)
    })

  }

  // TODO: some of this is generic as well (header, link, recipe name)
  return (
    <div>
      <Container fluid>

        <Row><h2>Tillagningar</h2></Row>
        <Row><NavLink to={ "/recipe/" + recipe.uriPath } as={ Link }><i className="fa fa-backward" aria-hidden="true"/>&nbsp; Tillbaka till receptet</NavLink></Row>

        <Row style={ { paddingTop: '20px' } }/>
        <Row>
          <table>
            <tbody>
            <tr>
              <th scope="row" style={ { width: '130px' } }>Recept</th>
              <td>{ recipe.name }</td>
            </tr>
            </tbody>
          </table>
        </Row>
        <Row style={ { paddingTop: '40px' } }/>

        <Jumbotron style={ { padding: "10px", marginTop: '20px' } }>
          <h4>Lägg till tillagning</h4>

          <Formik
            validationSchema={ schema }
            onSubmit={ (values, actions) => onSubmitFunction(navigate, recipe, values, actions) }
            initialValues={ initialValues }
          >
            { ({
                 handleSubmit,
                 handleChange,
                 handleBlur,
                 values,
                 touched,
                 isValid,
                 errors,
               }) => renderForm(handleSubmit, values, handleChange, touched, errors) }
          </Formik>
        </Jumbotron>

      </Container>
    </div>
  )

  function renderForm(handleSubmit, values, handleChange, touched, errors) {
    // DatePicker locales
    registerLocale('sv', sv)

    return (
      <Form noValidate onSubmit={ handleSubmit }>
        <Form.Group as={ Row } controlId="validationFormikDate">
          <Form.Label column sm={ 2 }>Datum</Form.Label>
          <Col sm={ 10 }>
            <Field name="date">
              { ({ field, form, meta }) => (
                <>
                  <DatePicker
                    selected={ date }
                    onChange={ (content) => {
                      setDate(content)

                      form.setFieldValue("date", content, true);
                      form.setFieldTouched("date", true, false);
                    } }
                    dateFormat="P"
                    locale="sv"
                  />
                  { meta.touched && meta.error && (
                    <div className="error">{ meta.error }</div>
                  ) }
                </>
              ) }
            </Field>
            <Form.Control.Feedback type="invalid">
              { errors.date }
            </Form.Control.Feedback>
          </Col>
        </Form.Group>


        <Form.Group as={ Row } controlId="validationFormikRating">
          <Form.Label column sm={ 2 }>Betyg</Form.Label>
          <Col sm={ 10 }>
            <Field name="rating" number>
              { ({ field, form, meta }) => (
                <>
                  <ReactStars
                    count={ 5 }
                    value={ field.value }
                    onChange={ (content, editor) => {
                      form.setFieldValue("rating", content, true);
                      form.setFieldTouched("rating", true, false);
                    } }
                    size={ 28 }
                    activeColor="#ffd700"
                    edit={ true }
                    half={false}
                  />

                  { meta.touched && meta.error && (
                    <div className="error">{ meta.error }</div>
                  ) }
                </>
              ) }
            </Field>
            <Form.Control.Feedback type="invalid">
              { errors.rating }
            </Form.Control.Feedback>
          </Col>
        </Form.Group>


        <Form.Group as={ Row } controlId="validationFormikComment">
          <Form.Label column sm={ 2 }>Övrigt</Form.Label>
          <Col sm={ 10 }>
            <Field
              name="comment">
              { ({ field, form, meta }) =>
                <Editor
                  textareaName="comment"
                  apiKey="eu5kbtumc6u03xfgve3evhllp0fg7n9wosz78n67kleuxoqt"
                  onEditorChange={ (content, editor) => {
                    form.setFieldValue("comment", content, true);
                    form.setFieldTouched("comment", true, false);
                  } }
                  init={ {
                    height: 200,
                    menubar: false,
                    plugins: [
                      'lists autoresize code'
                    ],
                    toolbar:
                      'undo redo | bold italic | bullist numlist | removeformat code | help',
                    max_height: 500
                  } }
                />
              }
            </Field>
            <Form.Control.Feedback type="invalid">
              { errors.comment }
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Row>
          <Col sm={ 2 }>&nbsp;</Col>
          <Col>
            <Button type="submit" className={ "text-center" }>Lägg till</Button>
          </Col>
        </Row>

      </Form>
    )
  }

}


export default RecipeCookInformationManager
