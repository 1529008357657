import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom"
import Spinner from "react-bootstrap/Spinner"
import {toast} from "react-toastify"
import '../../pages/recipe-tag.css'
import RecipeForm from "./RecipeForm";
import {useRecoilRefresher_UNSTABLE, useRecoilValue} from "recoil";
import {metadataAtom} from "../../states/metadata";
import {loggedInAtom} from "../../states/auth";
import axios from "axios";
import {handleHeaderMessages} from "../../utils/headerMessage";
import {refreshMetaData} from "../../redux/actions/metadataActions";

// TODO: extract Tag to own component?
const RecipeEditDetails = () => {
  const navigate = useNavigate()

  const {recipeId} = useParams();
  const metaDataRefresher = useRecoilRefresher_UNSTABLE(metadataAtom);
  const isLoggedIn = useRecoilValue(loggedInAtom);
  const [recipe, setRecipe] = useState(null)

  console.log("recipeId: ", recipeId)
  useEffect(() => {
    axios.get("/api/recipes/" + recipeId)
      .then(function (response) {
        console.log("Get recipe success:", response.data);
        setRecipe(response.data)
      });
  }, [recipeId]);


  // TODO: make this more generic (for example A LoggedInPage
  if (!isLoggedIn) {
    navigate("/")
    toast.error("Måste vara inloggad för att uppdatera recept")
  }

  if (!recipe) {
    return <Spinner animation="border" variant="primary"/>
  }

  return (
    <RecipeForm recipe={recipe}
                onSubmitFunction={onSubmitUpdateRecipe}
                metaDataRefresher={metaDataRefresher}/>
  )
}

export const onSubmitUpdateRecipe = (navigate, oldRecipe, values, actions, inputTags, metaDataRefresher) => {
  console.log("UpdateRecipe")

  // Map UI tag format to BE format...
  const tags = inputTags.map((tag) => {
    return tag.value
  })

  const ingredientsAsString = values.ingredientsString
  const stepsAsString = values.stepsString

  let body = {
    id: oldRecipe.id,
    name: values.name,
    category: values.category,
    wineRecommendationCategory: values.wineRecommendationCategory,
    tags: tags,
    description: values.description,
    cookingTime: values.cookingTime,
    servings: values.servings,
    ingredientsAsString: ingredientsAsString,
    stepsAsString: stepsAsString,
    source: {
      name: values.sourceName,
      url: values.sourceUrl
    },
    otherInformation: values.otherInformation,
    bump: values.bump
  }

  // updateRecipe(history, oldRecipe, values, actions, body);
  let url = '/api/recipes/' + oldRecipe.id
  console.log("Going to post to url " + url + ":", JSON.stringify(body, null, 2))
  axios.put(url, body).then((response) => {
    // console.log("Result: " + (JSON.stringify(response.data, null, 2)))
    actions.setSubmitting(false)
    navigate("/recipe/" + response.data.uriPath)
    handleHeaderMessages(response.headers)
    metaDataRefresher()
  }).catch(function (error) {
    console.log("Error:", error)
    toast.error("Fel uppstod: " + error)
  })
}

export default RecipeEditDetails

// function mapStateToProps(state) {
//   return {
//     recipes: state.recipesForHomePageReducer.recipes,
//     isLoading: state.recipesForHomePageReducer.isLoading
//   }
// }
//
// connect(
//   mapStateToProps
// )(onSubmit)