import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment/locale/sv';

export class DateTime extends Component {
  render() {
    if (!this.props.dateTime) {
      return "";
    }
    const dateToFormat = this.props.dateTime;
    return (
      <span>
        <Moment locale="sv" fromNow>{ dateToFormat }</Moment>
      </span>
    );
  }
}

export default DateTime;