import React from "react";

import Button from "react-bootstrap/Button";
import {Form} from "react-bootstrap";
import {toast} from "react-toastify";
import axios from "axios";

const RecipeImageUpload = ({recipeId, refreshRecipe}) => {

  let fileInput = React.createRef();

  function handleSubmit(event) {
    console.log("handleSubmit", event)
    event.preventDefault();

    let url = `/api/recipes/${recipeId}/images`;
    let formImages = fileInput.current.files;

    for (let image of formImages) {
      console.log("Image", image)

      let formData = new FormData();
      formData.append('file', image);

      // Upload the file one at a time - the backend doesn't support parallel writes :/
      axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        toast.success("Bilden " + image.name + " har laddats upp")
        refreshRecipe();
      }).catch(err => {
        console.log("Error", err)
        toast.error("Misslyckades med att ladda upp bilden " + image.name + ": " + err)
      })
    }
    /*uploadImages(this.recipeId, this.fileInput.current.files).then(r => {
      console.log("Files uploaded")
    })*/
  }

  return (
    <Form onSubmit={handleSubmit}>
      <h4>Ladda upp bild</h4>

      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Default file input example</Form.Label>
        <Form.Control type="file" custom ref={fileInput} />
        {/*multiple*/}
      </Form.Group>

      {/*<Form.Group controlId="formFile">
        <Form.File
          id="custom-file"
          label="Välj bild"
          ref={fileInput}
          custom
          multiple
        />
      </Form.Group>*/}

      <Button variant="primary" type="submit">
        Ladda upp
      </Button>

    </Form>
  );
}

export default RecipeImageUpload