import React, {useEffect, useState} from "react";
import Spinner from "react-bootstrap/Spinner";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import {Link, useNavigate} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {FormHeart} from "./FormHeart";
import ReactStars from "react-rating-stars-component";
import Form from "react-bootstrap/Form";
import {windowNoSleep} from "../App";
import {toast} from "react-toastify";
import {useRecoilRefresher_UNSTABLE, useRecoilValue} from "recoil";
import {currentUserAtom} from "../states/auth";
import axios from "axios";
import {handleHeaderMessages} from "../utils/headerMessage";
import {metadataAtom} from "../states/metadata";

const UserRecipeToolbar = ({recipe}) => {
  const currentUser = useRecoilValue(currentUserAtom);

  const navigate = useNavigate()
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [userData, setUserData] = useState(recipe.userData);
  const [preventSleep, setPreventSleep] = useState(true);
  const metaDataRefresher = useRecoilRefresher_UNSTABLE(metadataAtom);

  const handleCloseDeleteDialog = () => setShowDeleteDialog(false);
  const handleShowDeleteDialog = () => setShowDeleteDialog(true);
  const handleDeleteRecipe = () => {
    let url = '/api/recipes/' + recipe.id;
    axios.delete(url)
      .then((response) => {
        handleHeaderMessages(response.headers);
        metaDataRefresher();
      })
      .catch((error) => {
        toast.error("Fel uppstod när receptes försökte tas bort: " + error)
      });
    navigate("/")
  }

  const togglePreventSleep = () => {
    if (preventSleep) {
      windowNoSleep.enable()
      toast.success("Skärmsläckaren avstängd")
      setPreventSleep(false)
    } else {
      windowNoSleep.disable()
      toast.success("Skärmsläckaren aktiverad igen")
      setPreventSleep(true)
    }

  }

  // When userData changes, save it
  useEffect(() => {
    // Ensure that we don't trigger the update when the page loads
    if (userData === recipe.userData) {
      return
    }
    updateUserData(recipe.id, userData)
  }, [userData]);

  if (!currentUser) {
    return <Spinner animation="border" variant="primary"/>
  }

  // Should the use be able to delete and update? (there is backend safety as well ;))
  let canUpdate = (currentUser.id === recipe.created.by.id) || currentUser.isAdmin;
  console.log("canUpdate", canUpdate)

  function updateUserData(recipeId, userData) {
    let url = '/api/recipes/' + recipeId + "/userdata";

    axios.post(url, userData).then(response => {
      handleHeaderMessages(response.headers)
    }).catch(err => {
      toast.error("Misslyckades med att uppdatera 'userData'': " + err)
    })
  }

  return (
    <>
      <Navbar variant="light" className="recipe-tool-bar"
              style={{backgroundColor: "#a2d4fa", borderRadius: "12px", padding: "5px"}} sticky="top">

        <Navbar.Collapse id="responsive-navbar-nav">

          <Navbar.Text>
            <FormHeart favourite={userData && userData.favourite} onClickCallBack={handleChangeHeart}/>
          </Navbar.Text>

          <span className="divider-vertical"/>

          <div className="navbar-text" style={{lineHeight: "20px", width: "135px"}}>
            <ReactStars
              count={5}
              value={userData.rating}
              onChange={handleRateChange}
              size={26}
              activeColor="#ffd700"
              edit={true}
              half={false}
            />
          </div>

          <span className="divider-vertical"/>

          <div className="navbar-text" style={{lineHeight: "20px"}}>
            <div style={{width: "90px", textAlign: "left"}}>
              <Form.Check
                type="checkbox"
                label="Vill laga"
                id="wantToTest"
                name="wantToTest"
                checked={(userData.wantToTest === true)}
                onChange={handleCheckboxChange}
                custom
              />
              <Form.Check
                type="checkbox"
                label="Har lagat"
                id="hasTested"
                name="hasTested"
                checked={(userData.hasTested === true)}
                onChange={handleCheckboxChange}
                custom
              />
            </div>
          </div>

          <Navbar.Collapse className="justify-content-end">
            <NavDropdown title={<i className="fas fa-cog"/>} id="collasible-nav-dropdown">
              {canUpdate &&
                <NavDropdown.Item to={"/recipe/" + recipe.id + "/edit"} as={Link}><i className="fas fa-edit"/> Uppdatera
                  recept</NavDropdown.Item>
              }
              <NavDropdown.Item to={"/recipe/" + recipe.id + "/edit/images"} as={Link}><i
                className="fas fa-cloud-upload-alt"/> Bilder</NavDropdown.Item>
              <NavDropdown.Item to={"/recipe/" + recipe.id + "/cookInformation/add"} as={Link}><i
                className="fas fa-piggy-bank"/> Tillagningar</NavDropdown.Item>
              <NavDropdown.Item onClick={togglePreventSleep}><i className="fas fa-bed"/>&nbsp;
                {preventSleep ? 'Stäng av skärmsläckare' : 'Tillåt skärmsläckare'}
              </NavDropdown.Item>
              {canUpdate &&
                <>
                  <NavDropdown.Divider/>
                  <NavDropdown.Item onClick={handleShowDeleteDialog}>
                    <i className="fas fa-trash-alt"/> Ta bort
                  </NavDropdown.Item>
                </>
              }
            </NavDropdown>
          </Navbar.Collapse>
        </Navbar.Collapse>
      </Navbar>

      <Modal show={showDeleteDialog} onHide={handleCloseDeleteDialog}>
        <Modal.Header closeButton>
          <Modal.Title>Ta bort receptet?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Vill du verkligen ta bort receptet {recipe.name}?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteDialog}>
            Avbryt
          </Button>
          <Button variant="primary" onClick={handleDeleteRecipe}>
            Ja, ta bort!
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  function handleChangeHeart(value) {
    setUserData(prevState => {
      return {
        ...prevState,
        favourite: value
      }
    })
  }

// TODO: the rating component is forked from https://github.com/n49/react-stars
// check if https://github.com/ertanhasani/react-stars/issues/6 is fixed for example
  function handleRateChange(newRating) {
    setUserData(prevState => {
      return {
        ...prevState,
        rating: newRating
      }
    })
  }

  function handleCheckboxChange(event) {
    let value = event.target.value;
    let valueName = event.target.name

    // Checkbox special
    value = event.target.checked === true

    setUserData(prevState => {
      return {
        ...prevState,
        [valueName]: value
      }
    })
  }

}

export default UserRecipeToolbar