import React, { useEffect, useState } from 'react';
import Navbar from "react-bootstrap/Navbar";
import axios from "axios";
import './Footer.css'
import Moment from "react-moment";

const Footer = () => {
  const frontendVersion = window.ENV.BUILD_NUMBER;
  const frontendEnv = window.ENV.ENVIRONMENT;
  const frontendBuildDateTime = window.ENV.BUILD_DATE_TIME;

  const [ backendInfo, setBackendInfo ] = useState(undefined)

  useEffect(() => {
    axios.get('/actuator/info').then((response) => {
      console.log("response.data", response.data)
      setBackendInfo(response.data)
    });
  }, [ setBackendInfo ])

  return (
    <Navbar id="footer" className={ "footer" }>
      <Navbar.Text>
        © Lennart Häggkvist 2013-2024<br/>
        <b>Frontend</b>: { frontendVersion } ({ frontendEnv }) <Moment locale="sv" fromNow>{ frontendBuildDateTime }</Moment><br/>
        { backendInfo && backendInfo.build &&
        <>
          <b>Backend</b>: { backendInfo.build.version } ({ backendInfo.env }) <Moment locale="sv" fromNow>{ backendInfo.build.time }</Moment>
        </>
        }
      </Navbar.Text>
    </Navbar>
  );

}

export default Footer;