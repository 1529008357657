import React, {useEffect, useState} from 'react';
import {useRecoilValue} from "recoil";
import {metadataAtom} from "../states/metadata";

const CloudPage = () => {
  const metadata = useRecoilValue(metadataAtom);
  const [ reactWordCloudTags, setReactWordCloudTags ] = useState([])

  useEffect(() => {
    let tags = metadata.statistics.tags.map((tag) => {
      return {
        text: tag.text,
        value: tag.count
      }});
    setReactWordCloudTags(tags);
  }, [ metadata ])

  const options = {
    // colors: ['#a2c3f2', '#ff7f0e', 'white', '#27d6bc', '#d3e817', '#8c564b'],
    enableTooltip: true,
    deterministic: false,
    fontFamily: 'impact',
    fontSizes: [20, 60],
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: 1,
    rotations: 3,
    rotationAngles: [0, 90],
    scale: 'sqrt',
    spiral: 'archimedean',
    transitionDuration: 100,
  };

  return (
    <div>
      <div>
        TODO re-add!
        {/*<ReactWordcloud options={options} words={reactWordCloudTags}/>*/}
        {JSON.stringify(reactWordCloudTags)}
      </div>
    </div>
  );
}

export default CloudPage;