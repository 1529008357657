import React from "react";

function Jumbotron({children, style}) {
  return (
    <div className="jumbotron" style={style}>
      {children}
    </div>
  );
}

export default Jumbotron;